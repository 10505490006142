<template>
<div class="container-fluid">
    <div class="container-wrappero">
        <div class="content-page-header mb-4">
            <div class="breadcrumb-header">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb content_shadow">
                        <li class="breadcrumb-item">
                            <router-link to="/useradmin">User Admin</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Add User Admin </li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="content-body  mb-4">
            <div class="add-contact">
                <v-form class="g-3" @submit.prevent="submitUserAdmin()" v-model="valid" ref="useradminform">
                    <div class="row">
                        <div class="col-md-6 padding">
                            <label class="form-label">Name <span class="text-danger">*</span></label>
                            <v-text-field placeholder="Enter Admin Name" type="text" :rules="nameRules" v-model="useradmin.name" outlined dense></v-text-field>
                        </div>
                        <div class="col-md-6 padding">
                            <label class="form-label">Email <span class="text-danger">*</span></label>
                            <v-text-field placeholder="Enter Email" type="text" @keyup="email_check" :rules="emailRules" v-model="useradmin.email" outlined dense></v-text-field>
                        </div>
                        <div class="col-md-6 padding">
                            <label class="form-label">Password <span class="text-danger">*</span></label>
                            <v-text-field placeholder="Enter Password" type="password" :rules="passwordRules" v-model="useradmin.password" outlined dense></v-text-field>
                        </div>
                        <div class="col-md-6 padding">
                            <label for="inputEmail4" class="form-label">Status <span class="text-danger">*</span></label>
                            <v-select
                                :items="status_items"
                                v-model="useradmin.status"
                                :item-text="item=>item.name"
                                :item-value="item=>item.value"
                                :rules="statusRules"
                                outlined
                                dense
                                >
                            </v-select>
                        </div>
                        <div class="row">
                            <div class="col-md-6 padding">
                                <v-btn class="mr-4 red darken-2 text-white" type="button" @click="resetButton()">
                                    Reset
                                </v-btn>
                            </div>
                            <div class="col-md-6 padding">
                                <v-btn color="blue darken-4" class="f-right" type="submit" :loading="loading" dark>
                                    Submit
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </v-form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'addUserAdmin',
    data: () => ({
        valid: true,
        useradmin: {
            name: '',
            email: '',
            password: '',
            status: '',
        },
        status_items:[
            {id: 1,name: 'Active', value: 1},
            {id: 2,name: 'Inactive', value: 2}
        ],
        loading: false,
        nameRules: [
            v => !!v || 'Admin Name is required',
        ],
        emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "Enter valid email address",
        ],
        passwordRules: [
            v => !!v || 'Password is required',
            v => v.length >= 4 || "Min 4 characters",
        ],
        statusRules: [
            v => !!v || 'Status is required',
        ],
    }),
    methods: {
        resetButton(){
            this.$refs.useradminform.reset();
        },
        email_check(){
            if (this.useradmin.email) {
                axios.get("useradmin/email_check/"+this.useradmin.email)
                .then((result) => {
                    if (result.data.status == false) {
                        this.emailRules = [
                            v => !v || 'Email already taken!'
                        ]
                    } else {
                        this.emailRules = [
                            v => !!v || 'Email is required',
                            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
                        ]
                    }
                }).catch((error) => {
                    this.emailRules = [
                            v => !!v || 'Email is required',
                            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
                        ]
                });
            }
        },
        submitUserAdmin() {
            if (this.$refs.useradminform.validate()) {
                this.loading = true
                this.$store.dispatch("useradmin/storeUserAdmin", this.useradmin)
                .then((response) => {
                    this.$router.push('/useradmin')
                    this.$refs.useradminform.reset();
                }).catch((error) => {
                    this.loading = false
                })
            }
        }
    }
}
</script>
