export default {
    namespaced: true,
  
    state: () => ({
        designations: []
    }),
  
    getters: {
        designations(state){
            return state.designations
      },
    },
  
    mutations: {
      populateDesignation(state, newDesignation){
        state.designations = newDesignation;
      },
  
    },
  
    actions: {
      getDesignations({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("designation")
          .then(res => {
            console.log("Designation Data::: ",res.data.data)
            resolve(res);
            commit("populateDesignation",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },

      async storeDesignation({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios
            .post('designation', data)
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              console.log(error.response.data.errors);
              reject(error);
            });
        });
      },
    }
  };