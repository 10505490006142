<template>
  <div class="container-fluid">
    <div class="container-wrapper">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/miscellaneous">Miscellaneous</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">List</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- content-page-header end -->
      <div class="row">
        <div class="float-right text-right">
          <button @click="AddMiscellaneous()" class="btn btn-sm custom-btn mb-3 mr-2">
            <span><i class="fa fa-plus"></i></span> Add Miscellaneous
          </button>
        </div>
      </div>
      <div class="table-responsive">
        <datatable
          class="mt-2"
          title="Miscellaneous"
          :columns="tableColumns1"
          :rows="finalMiscellaneous"
          :printable="false"
        >
          <th slot="thead-tr">Actions</th>
          <template slot="tbody-tr" scope="props">
            <td>
              <button
                class="data-btn mr-2"
                @click="(e) => editItem(props.row, e)"
              >
                <i class="fa fa-edit"></i>
              </button>
              <button class="data-btn" @click="(e) => deleteItem(props.row, e)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </template>
        </datatable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DataTable from "vue-materialize-datatable";
export default {
  name: "Dashboard",

  data: () => ({
    tableColumns1: [
      {
        label: "Sl",
        field: "sno",
        numeric: false,
        html: false,
      },
      {
        label: "Employee",
        field: "employee",
        numeric: false,
        html: false,
      },
      {
        label: "Type",
        field: "type",
        numeric: false,
        html: false,
      },
      {
        label: "Year",
        field: "year",
        numeric: false,
        html: false,
      },
      {
        label: "Month",
        field: "month",
        numeric: false,
        html: false,
      },
      {
        label: "Comment",
        field: "comment",
        numeric: false,
        html: false,
      },
      {
        label: "Amount",
        field: "amount",
        numeric: false,
        html: false,
      },
      {
        label: "Status",
        field: "status",
        numeric: false,
        html: '<span>',
      },
    ],
  }),
  components: {
    datatable: DataTable,
  },

  async created() {
    this.getMiscellaneouses();
  },
  computed: {
    ...mapState("miscellaneous", ["miscellaneouses"]),
    finalMiscellaneous() {
      return this.miscellaneouses.length > 0
        ? this.miscellaneouses.map((miscellaneous, sl) => ({
            ...miscellaneous,
            sno: sl + 1,
            comment: miscellaneous.comment.substring(0, 50) + "..",
            employee: miscellaneous.employee ? miscellaneous.employee.name : "",
            status: miscellaneous.status == 1 ? '<span class="">Active </span>' : '<span class=""badge badge-success"">De-active </span>',
          }))
        : [];
    },
  },

  methods: {
    ...mapActions("miscellaneous", ["getMiscellaneouses"]),
    AddMiscellaneous() {
      this.$router.push("/add_miscellaneous").catch(() => {});
    },
    editItem(props, event) {
      console.log("props data peyechi", props, event);
      this.$router
        .push({ name: "EditMiscellaneous", params: { id: props.id } })
        .catch(() => {});
    },
    deleteItem(props, e) {
      this.$swal({
        title: "Delete Miscellaneous",
        text: "Are you sure you want to delete this Miscellaneous?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Delete!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed

          axios
            .delete("miscellaneous/" + props.id)
            .then((res) => {
              // this.feedlist = res.data;
              this.$swal("Successfully deleted");
              this.getMiscellaneouses();
            })
            .catch((err) => {
              console.log("Error", err);
            });
        }
      });
    },
  },
};
</script>

<style>
/* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
.data-btn {
  background-color: #607d8b !important;
  color: #fff;
  padding: 5px 10px 5px 8px !important;
}
.custom-btn {
  background-color: #607d8b !important;
}
</style>
