export default {
    namespaced: true,
    state: () => ({
      company_setting: []
    }),
  
    getters: {
      company_setting(state){
        return state.company_setting
      },
    },
    mutations: {
    saveCompanySetting(state,newCompanySetting)
      {
        state.company_setting.push(newCompanySetting);
      },
      updateCompanySetting(){
  
      },
      populateCompanySetting(state, newCompanySetting){
        state.company_setting = newCompanySetting
      }
    },
  
    actions: {
      async AddCompanySetting({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.post("company_setting",apiArg)
          .then(res => {
            console.log("company setting Data::: ",res.data.data)
            resolve(res);
            commit("saveCompanySetting",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async UpdateCompanySetting({ commit },apiArg){

        return new Promise((resolve, reject) => {
          axios.put("company_setting/"+apiArg.id,apiArg)
          .then(res => {
            console.log("company setting Data::: ",res.data.data)
            resolve(res);
            commit("updateCompanySetting",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async CompanySettingsDetails(ctx,apiArg){
        let isSuccess = false
        await axios.get("company_setting").then(response => {
          isSuccess = response.data.data
          ctx.commit('populateCompanySetting', response.data.data)
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
        },

      
    }
  };