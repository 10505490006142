<template>
<div class="container-fluid">
    <div class="container-wrapper">

        <div class="row">
            <div class="float-right text-right">
                <router-link to="/add-designation">
                    <button class="btn btn-sm custom-btn mb-3"><span><i class="fa fa-plus"></i></span> Add Designation</button>
                </router-link>
            </div>
        </div>
        <div class="table-responsive">
            <datatable class="mt-2 table-responsive" title="Designation" :columns="tableColumns1" :rows="finalDesignations" :exactSearch='true' :exportable="true" :printable="false">
                <th slot="thead-tr">
                    Actions
                </th>
                <template slot="tbody-tr" scope="props">
                    <td>
                        <button class="data-btn mr-2" @click="e => editItem(props.row, e)">
                            <i class="fa fa-edit"></i>
                        </button>
                        <button class="data-btn" @click="e => deleteItem(props.row, e)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </template>
            </datatable>
        </div>
    </div>

</div>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import DataTable from "vue-materialize-datatable";

export default {
    name: 'dashboard',
    data: () => ({
        tableColumns1: [{
                label: "Sl",
                field: "sno",
                value: 'sno',
                html: false
            },
            {
                label: "Name",
                field: "name",
                numeric: false,
                html: false
            },
            {
                label: "Description",
                field: "description",
                numeric: false,
                html: false
            },
        ],

    }),
    components: {
        "datatable": DataTable
    },

    async created() {
        await this.getDesignations();
    },
    computed: {
        ...mapState('designation', ['designations']),
        finalDesignations() {
            return this.designations.length > 0 ? this.designations.map((designation,sl)=>({...designation,sno: sl + 1,description: (designation.description && designation.description.length > 50 ? designation.description.substring(0,50)+".." : designation.description)})) : [];
        }
    },

    methods: {
        ...mapActions('designation', ['getDesignations']),
        editItem(props, e) {
            this.$router.push({
                name: 'edit-designation',
                params: {
                    id: props.id
                }
            }).catch(()=>{});
        },

        deleteItem(props, e) {
            this.$swal({
                title: "Delete this desgination?",
                text: "Are you sure? You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
               
                axios
                    .delete("designation/" +
                        props.id
                    )
                    .then((res) => {
                        // this.feedlist = res.data;
                        this.$swal('Successfully deleted');
                        this.getDesignations();
                    })
                    .catch((err) => {
                        console.log("Error", err);
                    });
                }
            });
        }
    },
};
</script>

<style>
/* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
</style>
