<template>
<div class="container-fluid">
    <div class="container-wrapper">
        <div class="row">
            <div class="float-right text-right">
                <router-link to="/add-holidays">
                    <button class="btn btn-sm custom-btn mb-3"><span><i class="fa fa-plus"></i></span> Add Holiday</button>
                </router-link>
            </div>
            <div class="col-md-3">
                <v-card>
                    <v-tabs background-color="indigo darken-4" active-class="True" center-active dark vertical>
                        <v-tab v-for="(month, i) in months" :key="i" @click="monthFilter(month.id)">{{ month.name }}</v-tab>
                    </v-tabs>
                </v-card>
            </div>
            <div class="col-md-9">
                <v-alert dense text type="success" dismissible v-if="occasionHolidaySuccess">
                    Successfully Updated
                </v-alert>
                <div class="text-center d-flex">
                    <div class="mr-2">
                        <strong>Weekend Holiday</strong>:
                    </div>
                    <div v-if="holidays.fixedHoliday" class="d-flex">
                        <div class="text-danger" v-for="(holiday,fix) in holidays.fixedHoliday" :key="'fixed' + fix">
                            {{holiday.day}}&nbsp;&nbsp;
                        </div>
                    </div>
                </div>
                <div class="text-muted"><small>(If you want to update weekend holiday, please add new weekend holiday, previous weekend holiday will be changed)</small></div>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Sl</th>
                            <th scope="col">Date</th>
                            <th scope="col">Day</th>
                            <th scope="col">Occasion</th>
                            <th scope="col">Description</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(holiday, index) in holidayrows" :key="'oc' + index">
                            <th scope="row">{{ index + 1}}</th>
                            <td>{{ moment(holiday.date).format('MMMM Do YYYY') }}</td>
                            <td>{{ moment(holiday.date).format('dddd') }}</td>
                            <td>{{ holiday.occasion }}</td>
                            <td>{{ holiday.description }}</td>
                            <td>
                                <v-dialog v-model="dialog" max-width="1000" close-delay="data-btn mr-2" dismissable>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="" dark v-bind="attrs" v-on="on" class="data-btn mr-2" @click="occasionHolidayModal(holiday)" elevation="6" small height="34" width="33">
                                            <i class="fa fa-edit"></i>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title class="text-h5 grey lighten-2">
                                            Update Occasion Holiday
                                        </v-card-title>
                                        <v-card-text>
                                            <br>
                                            <v-form class="row g-3" @submit.prevent="submitUpdateOcassionHoliday()" v-model="valid1" ref="occassionUpdateHolidayform">
                                                <div class="row">
                                                    <div class="col-md-6 padding">
                                                        <label for="Addcontact" class="form-label">Holidays Date <span class="text-danger">*</span>
                                                        </label>
                                                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field v-model="occasionHoliday.date" label="Select Date" :rules="occassionDateRules" prepend-inner-icon="mdi-calendar" prepend-icon="" :value="holiday.date" readonly v-bind="attrs" ref="date" @click:prepend-inner="$refs.date.$el.querySelector('input').click()" v-on="on" solo flat outlined dense></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="occasionHoliday.date" offset-y :active-picker.sync="activePicker" min="2022-01-01" max="2028-12-31" @change="save"></v-date-picker>
                                                        </v-menu>
                                                    </div>
                                                    <div class="col-md-6 padding">
                                                        <label for="Source" class="form-label">Occasion <span class="text-danger">*</span>
                                                        </label>
                                                        <v-text-field placeholder="Enter your occasion" v-model="occasionHoliday.occasion" :rules="occassionRules" type="text" outlined dense></v-text-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 padding">
                                                        <label for="Addcontact" class="form-label">Description</label>
                                                        <v-textarea name="input-7-1" v-model="occasionHoliday.description" placeholder="Enter the occasion description(optional)" outlined dense></v-textarea>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <!-- <div class="col-md-6 padding">
                                                        <v-btn class="mr-4 darken-3" type="button" @click="resetButton()" color="error">
                                                            Reset
                                                        </v-btn>
                                                    </div> -->
                                                    <div class="col-md-12 padding text-right">
                                                        <v-btn color="blue darken-4" class="f-right" type="submit" :loading="loading" dark>
                                                            Submit
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </v-form>
                                        </v-card-text>
                                        <v-divider></v-divider>
                                    </v-card>
                                </v-dialog>
                                <!-- modal -->
                                <v-btn color="dark error" depressed class="mr-2 btn btn-danger" @click="occasionHolidayDelete(holiday.id)" elevation="6" small height="34" width="33">
                                    <i class="fa fa-trash"></i>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import DataTable from "vue-materialize-datatable";
import moment from 'moment';
export default {
    name: 'holidayIndex',
    data: () => ({
        loading: false,
        menu: false,
        date: null,
        activePicker: null,
        valid1: false,
        months: [{
                id: 1,
                name: "January"
            },
            {
                id: 2,
                name: "February"
            },
            {
                id: 3,
                name: "March"
            },
            {
                id: 4,
                name: "April"
            },
            {
                id: 5,
                name: "May"
            },
            {
                id: 6,
                name: "June"
            },
            {
                id: 7,
                name: "July"
            },
            {
                id: 8,
                name: "August"
            },
            {
                id: 9,
                name: "September"
            },
            {
                id: 10,
                name: "October"
            },
            {
                id: 11,
                name: "November"
            },
            {
                id: 12,
                name: "December"
            },
        ],

        allHoliday: null,
        holidayrows: [],
        dialog: false,
        occasionHolidaySuccess: false,

        occasionHoliday: {
            id: '',
            date: '',
            occasion: '',
            description: ''
        },

        occassionDateRules: [
            v => !!v || 'Date is required',
        ],
        occassionRules: [
            v => !!v || 'Occasion name is required',
        ],

    }),

    components: {
        "datatable": DataTable
    },

    async created() {
        this.getHolidays();
        this.monthFilter(1);
    },
    computed: {
        ...mapState('holiday', ['holidays']),
       
    },

    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },

    },

    methods: {
        ...mapActions('holiday', ['getHolidays']),
        
        save(date) {
            this.$refs.menu.save(date)
        },

        monthFilter(id) {
            this.holidayrows = []
            this.holidays.occasionHoliday.forEach(element => {
                if (moment(element.date).format('M') == id) {
                    this.holidayrows.push(element)
                }
            });
            // console.log(this.holidayrows);
        },

        occasionHolidayModal(holiday) {
            this.dialog = true
            this.occasionHoliday = holiday;
        },

        submitUpdateOcassionHoliday() {
            this.loading = true
            // if(this.$refs.occassionUpdateHolidayform.validate()){
                axios
                .post("update-occasion-holiday",
                    this.occasionHoliday
                )
                .then((res) => {
                    // this.feedlist = res.data;
                    this.loading = false
                    
                    this.getHolidays();
                    
                    this.dialog = false;
                    this.occasionHolidaySuccess = true;
                })
                .catch((err) => {
                    console.log("Error", err);
                    this.loading = false
                });
            // }else{
            //     this.loading = false
            // }
           
        },

        occasionHolidayDelete(id) {
            this.$swal({
                title: "Delete this Occasion Holiday?",
                text: "Are you sure? You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios
                        .post("delete-occasion-holiday", {
                            id: id
                        })
                        .then((res) => {
                            // this.feedlist = res.data;
                            this.getHolidays();
                            this.monthFilter(1);
                            this.$swal('Successfully deleted');
                        })
                        .catch((err) => {
                            console.log("Error", err);
                        });
                }
            });
        }

    },
};
</script>

<style>
</style>
