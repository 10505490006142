 <template>
<div class="container-fluid">
    <div class="container-wrapper">
		<div class="row">
			<div class="float-right text-right">
				<button @click="AddProvidentFund()" class="btn btn-sm custom-btn mb-3"><span><i class="fa fa-plus"></i></span> Add Provident Fund</button>
				<button @click="printDiv('print')" class="btn btn-sm custom-btn mb-3 ml-1"><span><i class="fa fa-print"></i></span> Print</button>
			</div>
		</div>
		<v-alert class="mt-2" dense text type="success" dismissible v-if="fixedSuccess">
                    {{fixedSuccess}}
        </v-alert>
		<div class="table-responsive" >
			<datatable class="mt-2"
				title="Provident Fund"
				:columns="tableColumns1"
				:rows="finalProvidentfunds" :printable="false" id="print">
				<th slot="thead-tr">
					Actions
				</th>
				<template slot="tbody-tr" scope="props">
					<td>
						<button class="data-btn mr-2"
						@click="e => editItem(props.row, e)">
						<i class="fa fa-edit"></i>
					</button>
					<button class="data-btn"
						@click="e => deleteItem(props.row, e)">
						<i class="fa fa-trash"></i>
					</button>
					</td>
				</template>
			</datatable>
		</div>
    </div>

</div>
</template>

<script>
import {mapActions,mapState} from "vuex";
import moment from 'moment';
import DataTable from "vue-materialize-datatable";
export default {
    name: 'Dashboard',

    data: () => ({
		fixedSuccess: false,
        tableColumns1: [
			{
				label: "Sl",
				field: "sno",
				numeric: false,
				html: false
			},
			{
				label: "Employee Id",
				field: "employee_id",
				numeric: false,
				html: false
			},
			{
				label: "Employee Name",
				field: "employee",
				numeric: false,
				html: false
			},
			{
				label: "Previous P.F",
				field: "previous_provident_fund",
				numeric: false,
				html: false
			},
			{
				label: "P.F",
				field: "provident_fund",
				numeric: false,
				html: false
			},
			{
                label: "Status",
                field: "status",
                numeric: false,
                html: '<span>',
            },
		],

    }),
    components: {
        "datatable": DataTable
    },

    async created() {
       this.getProvidentFunds();
    },
    computed: {
        ...mapState('provident',['provident_funds']),
		finalProvidentfunds()
		{
			return this.provident_funds.length > 0 ? this.provident_funds.map((fund,sl)=>({...fund,sno: sl + 1,employee: (fund.employee ? fund.employee.name : ''),employee_id: (fund.employee ? fund.employee.employee_id : ''),
			status: fund.status == 1 ? '<span class="">Active </span>' : '<span class=""badge badge-success"">De-active </span>'})) : [];
		}
    },

    methods: { 
		...mapActions('provident',['getProvidentFunds']),
		AddProvidentFund()
		{
			this.$router.push('/add_provident_fund').catch(()=>{});
		},
		
		editItem(props,event)
		{
			console.log('props data peyechi', props, event);
			this.$router.push({name:'EditProvidentFund', params: { id: props.id}}).catch(()=>{});
		},
		deleteItem(props, e) {
            this.$swal({
                title: "Delete Provident Fund",
                text: "Are you sure you want to delete this provident fund?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Delete!"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
               
                axios
                    .delete("provident_fund/" +
                        props.id
                    )
                    .then((res) => {
                        // this.feedlist = res.data;
                        this.$swal('Successfully deleted');
                        this.getProvidentFunds();
                    })
                    .catch((err) => {
                        console.log("Error", err);
                    });
                }
            });
        },
		printDiv(divID) {
                var divContents = document.getElementById("print").innerHTML;
                var a = window.open('', '', '');
                a.document.write('<html><head><style type="text/css">');
                // a.document.write('<link rel="stylesheet" href="/assets/backend/css/bootstrap.min.css" type="text/css" />');
                a.document.write('body,td,th{font-family:Arial, Helvetica, sans-serif;font-size:13px;color:#000000}');
                a.document.write('.material-table td,.material-table th {border: 1px solid #e3ebf3; }');
                a.document.write('thead tr th {color: rgb(0 0 0);    font-size: 13px;    height: 45px !important;}');
                a.document.write('table{border-collapse:collapse; margin:0 auto;}');
                a.document.write('table th:last-child, table td:last-child{display:none !important}');
                a.document.write('.table-header{display:none !important}');
                a.document.write('.table-footer{display:none !important}');
                a.document.write('#search-input-container{display:none !important}');
                a.document.write('tbody, td, tfoot, th, thead, tr {border: 1px solid #e3ebf3 !important; text-align: center;}');
                a.document.write('thead th{font-size:13px!important;}');
                a.document.write('table td, table tr td {    padding: 3px 2px !important;}');
                a.document.write('table td, img {    height: 70px !important; width :70px !important}');
                a.document.write('.bottom-line{position:fixed; transform: translateX(-50%); left:50%;bottom:0}');
                a.document.write('h3,h2,h1,p{text-align:center;}');
                a.document.write('</style></head><body> <h1>Jamalpur Central Hospital Ltd</h1><h2>Amlapara, Jamalpur</h2><h3>Roster Manage List </h3>');
                a.document.write(divContents);
                a.document.write('<p class="bottom-line"> This report is system generated, no signiture needed </p>');
                a.document.write('</body></html>');
                a.document.close();
                a.focus();
                a.print();
                // document.body.innerHTML = oldPage;
            }
    },
};
</script>

<style>
   /* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
   .data-btn{
		background-color: #607d8b!important;
		color: #fff;
		padding: 5px 10px 5px 8px!important;
   }
   .custom-btn{
	background-color: #607d8b!important;
   }
   .input-wrapper {
		position: relative;
	}
            
	.input-wrapper [type=file] {
		height: 0;
		overflow: hidden;
		width: 0;
	}
            
	.input-wrapper [type=file]+label {

		border: none;

		color: #fff;
		cursor: pointer;
		display: inline-block;
		outline: none;
		position: relative;
		transition: all 0.3s;
		vertical-align: middle;
	}
   
</style>
