<template>
  <div class="container-fluid">
    <div class="container-wrappero">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/miscellaneous">Miscellaneous</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{
                  miscellaneous.id != ""
                    ? "Edit Miscellaneous"
                    : "Add Miscellaneous"
                }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- content-page-header end -->
      <div class="content-body mb-4">
        <div class="add-contact">
          <div class="accordion-body">
            <v-form
              @submit="saveMiscellaneous"
              v-model="valid"
              ref="miscellaneousform"
              lazy-validation
            >
              <div class="row g-3">
                <div class="col-md-3">
                  <label for="inputEmail4" class="form-label"
                    >Select Employee <span class="text-danger">*</span></label
                  >
                  <v-autocomplete
                    :items="employees"
                    v-model="miscellaneous.employee_id"
                    :item-text="
                      (item) => item.name + ' (' + item.employee_id + ')'
                    "
                    :item-value="(item) => item.id"
                    :rules="employeeRules"
                    outlined
                    dense
                  ></v-autocomplete>
                </div>
                <div class="col-md-3">
                  <label for="inputEmail4" class="form-label">Type <span class="text-danger">*</span></label>
                  <v-select
                    :items="type"
                    v-model="miscellaneous.type"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.value"
                    :rules="requiredRules"
                    outlined
                    dense
                  ></v-select>
                </div>
                <div class="col-md-3">
                  <label for="inputEmail4" class="form-label">Year <span class="text-danger">*</span></label>
                  <v-autocomplete
                    :items="year"
                    v-model="miscellaneous.year"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.value"
                    :rules="yearRules"
                    outlined
                    dense
                  ></v-autocomplete>
                </div>
                <div class="col-md-3">
                  <label for="inputEmail4" class="form-label">Month <span class="text-danger">*</span></label>
                  <v-select
                    :items="month"
                    v-model="miscellaneous.month"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.value"
                    :rules="monthRules"
                    outlined
                    dense
                  ></v-select>
                </div>
                <div class="col-md-12">
                  <label class="form-label">Comment <span class="text-danger">*</span></label>
                  <v-textarea
                    placeholder="Enter comment"
                    type="text"
                    rows="2"
                    v-model="miscellaneous.comment"
                    :rules="reasonRules"
                    outlined
                    dense
                  ></v-textarea>
                </div>
                <div class="col-md-6">
                  <label class="form-label">Amount <span class="text-danger">*</span></label>
                  <v-text-field
                    placeholder="Enter amount"
                    v-model="miscellaneous.amount"
                    type="number"
                    step="0.01"
                    value="0.00"
                    outlined
                    :rules="reasonRules"
                    dense
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <label for="inputEmail4" class="form-label">Status</label>
                  <v-select
                    :items="status_items"
                    v-model="miscellaneous.status"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.value"
                    outlined
                    dense
                  ></v-select>
                </div>
              </div>
              <div class="submit-section">
                <div class="">
                  <v-btn
                    class="mr-4 custom-btn"
                    type="button"
                    @click="resetButton()"
                    color="error"
                  >
                    Reset
                  </v-btn>
                </div>
                <div class="">
                  <v-btn
                    class="custom-btn f-right"
                    color="blue darken-4"
                    type="submit"
                    :loading="loading"
                    dark
                  >
                    Submit
                  </v-btn>
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { de } from "vue-materialize-datatable/src/locales";
import { mapState, mapActions } from "vuex";
export default {
  name: "AddMiscellaneous",

  data: () => ({
    miscellaneous: {
      id: "",
      employee_id: "",
      type: "",
      month: "",
      year: "",
      comment: "",
      amount: "",
      status: "",
    },
    loading: false,
    valid: true,
    error: "",
    month: [
      {id: 1, value: 1, name: 'January'}, 
      {id: 2, value: 2, name: 'February'}, 
      {id: 3, value: 3, name: 'March'}, 
      {id: 4, value: 4, name: 'April'}, 
      {id: 5, value: 5, name: 'May'}, 
      {id: 6, value: 6, name: 'June'}, 
      {id: 7, value: 7, name: 'July'}, 
      {id: 8, value: 8, name: 'August'}, 
      {id: 9, value: 9, name: 'September'}, 
      {id: 10, value: 10, name: 'October'}, 
      {id: 11, value: 11, name: 'November'}, 
      {id: 12, value: 12, name: 'December'}, 
    ],
    year: [
      { id: 0, name: "2021", value: "2021" },
      { id: 1, name: "2022", value: "2022" },
      { id: 2, name: "2023", value: "2023" },
      { id: 3, name: "2024", value: "2024" },
      { id: 4, name: "2025", value: "2025" },
      { id: 5, name: "2026", value: "2026" },
      { id: 6, name: "2027", value: "2027" },
      { id: 7, name: "2028", value: "2028" },
      { id: 8, name: "2029", value: "2029" },
      { id: 9, name: "2030", value: "2030" },
      { id: 10, name: "2031 ", value: "2031" },
      { id: 11, name: "2032", value: "2032" },
      { id: 11, name: "2033", value: "2033" },
      { id: 12, name: "2034", value: "2034" },
      { id: 13, name: "2035", value: "2035" },
    ],
    type: [
      { id: 0, name: "Addition", value: "addition" },
      { id: 1, name: "Deduction", value: "deduction" },
    ],
    status_items: [
      { id: 0, name: "Active", value: 1 },
      { id: 1, name: "De-active", value: 0 },
    ],
    requiredRules: [(v) => !!v || "Field is required"],
    monthRules: [(v) => !!v || "Month is required"],
    yearRules: [(v) => !!v || "Year is required"],
    reasonRules: [(v) => !!v || "Comment is required"],
    employeeRules: [(v) => !!v || "Employee is required"],
  }),
  async created() {
    this.getEmployees();
    if (this.$route.params.id) {
      this.getMiscellaneousDetails();
    }
  },
  computed: {
    ...mapState("employee", ["employees"]),
  },
  methods: {
    ...mapActions("employee", ["getEmployees"]),
    saveMiscellaneous(event) {
      event.preventDefault();
      this.loading = true;
      let isValidate = true;
      this.error = "";
      this.$refs.miscellaneousform.validate();
      this.$refs.miscellaneousform.inputs.forEach((input) => {
        if (input.hasError) {
          isValidate = false;
        }
      });
      if (!isValidate) {
        this.loading=false;
        return;
      }
      if (this.miscellaneous.id == "") {
        this.$store
          .dispatch("miscellaneous/AddMiscellaneous", this.miscellaneous)
          .then((response) => {
            this.$router.push("/miscellaneous").catch(() => {});
          })
          .catch(() => {});
      } else {
        this.$store
          .dispatch("miscellaneous/UpdateMiscellaneous", this.miscellaneous)
          .then(() => {
            this.$router.push("/miscellaneous").catch(() => {});
          })
          .catch(() => {});
      }
    },
    getMiscellaneousDetails() {
      this.$store
        .dispatch("miscellaneous/Miscellaneousdetails", this.$route.params.id)
        .then((res) => {
          if (res) {
            (this.miscellaneous.id = res.id),
              (this.miscellaneous.employee_id = res.employee_id),
              (this.miscellaneous.type = res.type),
              (this.miscellaneous.month = res.month),
              (this.miscellaneous.year = res.year),
              (this.miscellaneous.amount = res.amount),
              (this.miscellaneous.status = res.status),
              (this.miscellaneous.comment = res.comment);
          }
        });
    },
    resetButton() {
      this.$refs.miscellaneousform.reset();
    },
  },
};
</script>
<style>
  .submit-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>