<template>
  <div class="container-fluid">
    <div class="container-wrapper">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/leave">Leave Application</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">List</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- content-page-header end -->
      <div class="row">
        <div class="float-right text-right">
          <button @click="AddLeave()" class="btn btn-sm custom-btn mb-3 mr-2">
            <span><i class="fa fa-plus"></i></span> Add Leave
          </button>
        </div>
      </div>
      <div class="table-responsive">
        <datatable
          class="mt-2"
          title="Leave Application"
          :columns="tableColumns1"
          :rows="finalLeaves"
          :printable="false"
        >
          <th slot="thead-tr">Actions</th>
          <template slot="tbody-tr" scope="props">
            <td>
              <button
                class="data-btn mr-2"
                @click="(e) => editItem(props.row, e)"
              >
                <i class="fa fa-edit"></i>
              </button>
              <button class="data-btn" @click="(e) => deleteItem(props.row, e)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </template>
        </datatable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DataTable from "vue-materialize-datatable";
export default {
  name: "Dashboard",

  data: () => ({
    tableColumns1: [
      {
        label: "Sl",
        field: "sno",
        numeric: false,
        html: false,
      },
      {
        label: "Employee",
        field: "employee",
        numeric: false,
        html: false,
      },
      {
        label: "From Date",
        field: "from_date",
        numeric: false,
        html: false,
      },
      {
        label: "To Date",
        field: "to_date",
        numeric: false,
        html: false,
      },
      {
        label: "Total Days",
        field: "total_day",
        numeric: false,
        html: false,
      },
      {
        label: "Leave Type",
        field: "leave_type",
        numeric: false,
        html: false,
      },
      {
        label: "Reason",
        field: "reason",
        numeric: false,
        html: false,
      },
      {
        label: "Applied On",
        field: "applied_on",
        numeric: false,
        html: false,
      },
      {
        label: "Status",
        field: "status",
        numeric: false,
        html: false,
      },
    ],
    // tableRows2: [
    // 	{
    // 		charName: "Abu",
    // 		firstAppearance: "Alladin (1992)",
    // 		createdBy: "Joe Grant",
    // 		voicedBy: "Frank Welker"
    // 	},
    // 	{
    // 		charName: "Magic Carpet",
    // 		firstAppearance: "Alladin (1992)",
    // 		createdBy: "Randy Cartwright",
    // 		voicedBy: "N/A"
    // 	},
    // 	{
    // 		charName: "The Sultan",
    // 		firstAppearance: "Alladin (1992)",
    // 		createdBy: "Navid Negahban",
    // 		voicedBy: "Douglas Seale"
    // 	}
    // ],
  }),
  components: {
    datatable: DataTable,
  },

  async created() {
    this.getLeaves();
  },
  computed: {
    ...mapState("leave", ["leaves"]),
    finalLeaves() {
      return this.leaves.length > 0
        ? this.leaves.map((leave, sl) => ({
            ...leave,
            sno: sl + 1,
            reason: leave.reason.substring(0, 50) + "..",
            employee: leave.employee ? leave.employee.name : "",
          }))
        : [];
    },
  },

  methods: {
    ...mapActions("leave", ["getLeaves"]),
    AddLeave() {
      this.$router.push("/add_leave").catch(() => {});
    },
    editItem(props, event) {
      console.log("props data peyechi", props, event);
      this.$router
        .push({ name: "EditLeave", params: { id: props.id } })
        .catch(() => {});
    },
    deleteItem(props, e) {
      this.$swal({
        title: "Delete Leave Appliction",
        text: "Are you sure you want to delete this Leave application?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Delete!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed

          axios
            .delete("leave_application/" + props.id)
            .then((res) => {
              // this.feedlist = res.data;
              this.$swal("Successfully deleted");
              this.getLeaves();
            })
            .catch((err) => {
              console.log("Error", err);
            });
        }
      });
    },
  },
};
</script>

<style>
/* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
.data-btn {
  background-color: #607d8b !important;
  color: #fff;
  padding: 5px 10px 5px 8px !important;
}
.custom-btn {
  background-color: #607d8b !important;
}
</style>
