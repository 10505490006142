<template>
  <div class="container-fluid">
    <div class="container-wrappero">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/roaster_management">Roaster Management</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Add Roaster
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- content-page-header end -->
      <div class="content-body mb-4">
        <div class="add-contact">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Roaster Details
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <v-form
                    @submit="saveRoaster"
                    v-model="valid"
                    ref="roasterform"
                    lazy-validation
                  >
                    <div class="row g-3">
                      <div class="col-md-6">
                        <label for="inputEmail4" class="form-label"
                          >Employee <span class="text-danger">*</span></label
                        >
                        <v-autocomplete
                          placeholder="Select Employee"
                          type="text"
                          v-model="roaster.emp_id"
                          :rules="employeeRules"
                          :items="employees"
                          :item-text="(item) => item.name"
                          :item-value="(item) => item.id"
                          outlined
                          dense
                        ></v-autocomplete>
                      </div>

                      <div class="col-md-3" v-if="roaster.id == ''">
                        <label for="From Date" class="form-label">From Date <span class="text-danger">*</span></label>
                        <v-text-field
                          placeholder="Enter Date"
                          label=""
                          type="date"
                          v-model="roaster.from_date"
                          :rules="requiredRules"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-3" v-if="roaster.id == ''">
                        <label for="To Date" class="form-label">To Date <span class="text-danger">*</span></label>
                        <v-text-field
                          placeholder="Enter Date"
                          label=""
                          type="date"
                          v-model="roaster.to_date"
                          :rules="requiredRules"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-3" v-else>
                        <label for="To Date" class="form-label">To Date <span class="text-danger">*</span></label>
                        <v-text-field
                          placeholder="Enter Date"
                          label=""
                          type="date"
                          v-model="roaster.date"
                          :rules="requiredRules"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                      <!-- <div v-if="roaster.dates">
                        <div class="w-100 row" v-for="(range, index) in roaster.dates" :key="index"> -->
                          
                          <div class="col-md-4">
                            <label for="To Date" class="form-label">Shift <span class="text-danger">*</span></label>
                            <v-select
                              placeholder="Select Shift"
                              label=""
                              :items="shifts"
                              :item-text="(item) => item.name"
                              :item-value="(item) => item.id"
                              v-model="roaster.shift"
                              :rules="requiredRules"
                              outlined
                              dense
                            ></v-select>
                          </div>
                          <div class="col-md-4">
                            <label for="Start time" class="form-label"
                              >Start Time <span class="text-danger">*</span>
                            </label>
                            <v-text-field
                              placeholder="Enter start time"
                              label=""
                              type="time"
                              v-model="roaster.start_time"
                              :rules="requiredRules"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                          <div class="col-md-4">
                            <label for="End time" class="form-label"
                              >End Time <span class="text-danger">*</span>
                            </label>
                            <v-text-field
                              placeholder="Enter end time"
                              label=""
                              type="time"
                              v-model="roaster.end_time"
                              :rules="requiredRules"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        <!-- </div>
                      </div> -->
                      <div class="col-md-6">
                        <label for="inputEmail4" class="form-label"
                          >Status <span class="text-danger">*</span></label
                        >
                        <v-select
                          :items="status_items"
                          v-model="roaster.status"
                          :item-text="(item) => item.name"
                          :item-value="(item) => item.value"
                          :rules="requiredRules"
                          outlined
                          dense
                        ></v-select>
                      </div>
                    </div>
                    <div class="submit-section">
                      <div class="">
                        <v-btn
                          class="mr-4 custom-btn"
                          type="button"
                          @click="resetButton()"
                          color="error"
                        >
                          Reset
                        </v-btn>
                      </div>
                      <div class="">
                        <v-btn
                          class="custom-btn f-right"
                          color="blue darken-4"
                          type="submit"
                          :loading="loading"
                          dark
                        >
                          Submit
                        </v-btn>
                      </div>
                    </div>
                  </v-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { de } from "vue-materialize-datatable/src/locales";
import { mapState, mapActions } from "vuex";
export default {
  name: "AddRoaster",

  data: () => ({
    roaster: {
      id: "",
      employee_id: "",
      emp_id: "",
      start_time: "",
      date: "",
      end_time: "",
      shift: "",
      from_date: "",
      to_date: "",
      status: "",
    },
    loading: false,
    valid: true,
    error: "",
    status_items: [
      { id: 0, name: "Active", value: 1 },
      { id: 1, name: "De-active", value: 0 },
    ],
    shifts: [
      { id: 1, name: "Day", value: 'Day' },
      { id: 2, name: "Evening", value: 'Evening' },
      { id: 3, name: "Night", value: 'Night' },
    ],
    requiredRules: [
      (v) => !!v || "Field is required",
    ],
    employeeRules: [(v) => !!v || "Select employee"],
  }),
  async created() {
    this.getEmployees();
    if (this.$route.params.id) {
      this.getRoasterDetails();
    }
  },
  computed: {
    ...mapState("employee", ["employees"]),
  },
  methods: {
    ...mapActions("employee", ["getEmployees"]),
    saveRoaster(event) {
      event.preventDefault();
      this.loading = true;
      let isValidate = true;
      this.error = "";
      this.$refs.roasterform.validate();
      this.$refs.roasterform.inputs.forEach((input) => {
        if (input.hasError) {
          isValidate = false;
        }
      });
      if (!isValidate) {
        this.loading = false
        return;
      }
      if (this.roaster.id == "") {
        let employee = this.employees.find(
          (employee) => employee.id == this.roaster.emp_id
        );
        console.log("employee peyechi", employee);
        this.roaster.employee_id = employee.employee_id;
        this.$store
          .dispatch("roaster/AddRoaster", this.roaster)
          .then((response) => {
            this.$router.push("/roaster_management").catch(() => {});
          })
          .catch(() => {});
      } else {
        this.$store
          .dispatch("roaster/UpdateRoaster", this.roaster)
          .then((response) => {
            this.$router.push("/roaster_management").catch(() => {});
          })
          .catch(() => {});
      }
    },
    getDateRange()
    {
      if(this.roaster.from_date && this.roaster.to_date){
        let data = {
          from_date : this.roaster.from_date,
          to_date : this.roaster.to_date,
          employee_id: this.roaster.emp_id
        }
        this.$store
        .dispatch("roaster/getDateRange", data)
        .then((res) => {
          console.log("response data peyechi",res);
          let current_range = res;
          this.roaster.dates = [];
          current_range.forEach((range)=>{
            let last_id = this.roaster.dates.length ? this.roaster.dates[this.roaster.dates.length - 1].id : 0;
            this.roaster.dates.push({
                id:last_id + 1,
                date: range,
                shift: '',
                roaster_id: '',
                start_time:'',
                end_time: '',
            })
            
          })
          console.log("roaster date range peyechi", this.roaster.dates)
        })
      }
    },
    getRoasterDetails() {
      this.$store
        .dispatch("roaster/Roasterdetails", this.$route.params.id)
        .then((res) => {
          if (res) {
            (this.roaster.id = res.id),
              (this.roaster.from_date = res.date),
              (this.roaster.to_date = res.date),
              (this.roaster.date = res.date),
              (this.roaster.shift = res.shift),
              (this.roaster.emp_id = res.emp_id),
              (this.roaster.employee_id = res.employee_id),
              (this.roaster.start_time = res.start_time),
              (this.roaster.end_time = res.end_time),
              (this.roaster.status = res.status);
          }
        });
    },
    resetButton() {
      this.$refs.roasterform.reset();
    },
  },
};
</script>
<style>
    .submit-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>