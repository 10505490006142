<template>
  <div>
    
  </div>
</template>

<script>
export default {
  name: "HomePage",

  data: () => ({
    active: false,
  }),
  components: {
    
  },

  methods: {
    DownFetures(active) {
      this.active = active;
    },
  },
};
</script>
<style>
</style>