export default {
    namespaced: true,
  
    state: () => ({
      employeeforsalary: []
    }),
  
    getters: {
      employeeforsalary(state){
        return state.employeeforsalary
      },
    },
  
    mutations: {
      populateEmployeeforSalary(state, newEmployeeforSalary){
        state.employeeforsalary = newEmployeeforSalary;
      },
    },
  
    actions: {
      async getEmployeeforSalary({ commit },apiArg){
        let isSuccess = false
        await axios.get("employee/"+apiArg+"/edit").then(response => {
          isSuccess = response.data.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
      },
      async SalaryProcess({ commit },apiArg){
        let isSuccess = false
        await axios.post("salary_process",apiArg).then(response => {
          isSuccess = response.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
      },
      async SalaryReport({ commit },apiArg){
        let isSuccess = false
        await axios.post("salary_report",apiArg).then(response => {
          isSuccess = response.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
      },
      async SalarySummary({ commit },apiArg){
        let isSuccess = false
        await axios.post("salary_summary",apiArg).then(response => {
          isSuccess = response.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
      },
    }
  };