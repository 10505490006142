export default {
    namespaced: true,
  
    state: () => ({
      departments: []
    }),
  
    getters: {
      departments(state){
        return state.departments
      }
    },
  
    mutations: {
      populateDepartment(state, newDepartment){
        state.departments = newDepartment;
      },

      saveDepartment(state,newDepartment)
      {
        state.departments.push(newDepartment);
      },
      updateDepartment(state,newDepartment)
      {
       
      }
    },
  
    actions: {
      async getDepartments({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("department")
          .then(res => {
            console.log("Department Data::: ",res.data.data)
            resolve(res);
            commit("populateDepartment",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async AddDepartment({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.post("department",apiArg)
          .then(res => {
            console.log("Department Data::: ",res.data.data)
            resolve(res);
            commit("saveDepartment",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async UpdateDepartment({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.put("department/"+apiArg.id,apiArg)
          .then(res => {
            console.log("Department Data::: ",res.data.data)
            resolve(res);
            commit("updateDepartment",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async Departmentdetails(ctx,apiArg){
        let isSuccess = false
        await axios.get("department/"+apiArg+"/edit").then(response => {
          isSuccess = response.data.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
        },
    }
  };