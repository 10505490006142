export default {
    namespaced: true,
  
    state: () => ({
      provident_funds: []
    }),
  
    getters: {
      provident_funds(state){
        return state.provident_funds
      }
    },
  
    mutations: {
      populateProvidentFund(state, newProvidentFund){
        state.provident_funds = newProvidentFund;
      },

      saveProvidentFund(state,newProvidentFund)
      {
        state.provident_funds.push(newProvidentFund);
      },
      updateProvidentFund(state,newProvidentFund)
      {
       
      },
      
    },
  
    actions: {
      async getProvidentFunds({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("provident_fund")
          .then(res => {
            console.log("Provident Fund Data::: ",res.data.data)
            resolve(res);
            commit("populateProvidentFund",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async getDateRange({commit}, apiArg){
        let isSuccess = false
        await axios.post("roaster_date_range",apiArg)
          .then(response => {
            isSuccess = response.data.data
            console.log(response)
            return isSuccess
            })
            .catch(error => {
                isSuccess = false;
                console.log(error)
            })
            return isSuccess;
        },
      async AddProvidentFund({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.post("provident_fund",apiArg)
          .then(res => {
            console.log("Provident Fund Data::: ",res.data.data)
            resolve(res);
            commit("saveProvidentFund",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
    
      async UpdateProvidentFund({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.put("provident_fund/"+apiArg.id,apiArg)
          .then(res => {
            console.log("Provident Fund Data::: ",res.data.data)
            resolve(res);
            commit("updateProvidentFund",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async ProvidentFunddetails(ctx,apiArg){
        let isSuccess = false
        await axios.get("provident_fund/"+apiArg+"/edit").then(response => {
          isSuccess = response.data.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
        },
    }
  };