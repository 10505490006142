export default {
    namespaced: true,
  
    state: () => ({
      holidays: []
    }),
  
    getters: {
      holidays(state){
            return state.holidays
      },
    },
  
    mutations: {
      populateHoliday(state, newHoliday){
        state.holidays = newHoliday;
      },
  
    },
  
    actions: {
      async storeFixedHoliday({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios
            .post('fixed-holiday', data)
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              console.log(error.response.data.errors);
              reject(error);
            });
        });
      },

      async storeOccasionHoliday({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios
            .post('occasion-holiday', data)
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              console.log(error.response.data.errors);
              reject(error);
            });
        });
      },

      async getHolidays({commit}){
        return new Promise((resolve, reject) => {
          axios
            .get('get-holiday')
            .then((res) => {
              resolve(res);
              commit("populateHoliday",res.data.data);
            })
            .catch((error) => {
              console.log(error.response.data.errors);
              reject(error);
            });
        })
      }
    }
  };