export default {
    namespaced: true,
  
    state: () => ({
        useradmins: [],
        report: []
    }),
  
    getters: {
      useradmins(state){
          return state.useradmins
      },
      report(state){
          return state.report
      },
    },
  
    mutations: {
      populateUserAdmin(state, newUserAdmin){
        state.useradmins = newUserAdmin;
      },
      saveUserAdmin(state,newUserAdmin)
      {
        state.useradmins.push(newUserAdmin);
      },
      populateDashBoardReport(state,newDashboardReport){
        state.report = newDashboardReport
      }
    },
  
    actions: {
      getUserAdmins({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("useradmin")
          .then(res => {
            resolve(res);
            commit("populateUserAdmin", res.data.data);
          })
          .catch(error => {
            reject(error)
          })
        });
      },
      getDashboardReport({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("dashboard_report")
          .then(res => {
            resolve(res);
            commit("populateDashBoardReport", res.data.data);
          })
          .catch(error => {
            reject(error)
          })
        });
      },

      async storeUserAdmin({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios.post('useradmin', data)
            .then((result) => {
              resolve(result);
              commit("saveUserAdmin", result.data.data);
            })
            .catch((error) => {
              reject(error)
            });
        });
      },

      async UserAdmins(ctx,apiArg){
        let isSuccess = false
        await axios.get("useradmin/"+apiArg+"/edit").then(response => {
          isSuccess = response.data.data
          return isSuccess
        }).catch(error => {
              isSuccess = false;
          })
          return isSuccess;
        },
    }
  };