<template>
    <div class="container-wrappero">
        <div class="content-page-header mb-4">
            <div class="breadcrumb-header">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb content_shadow">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard">Dashboard</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Roster Report </li>
                    </ol>
                </nav>
            </div>
        </div>
        <v-alert class="mt-4" dense text type="success" dismissible v-if="fixedSuccess">
            {{fixedSuccess}}
        </v-alert>
        <v-alert class="mt-4" dense text type="error" dismissible v-if="fixedError">
            {{fixedError}}
        </v-alert>
        <div class="content-body  mb-4">
            <v-form class="g-3" ref="rosterreportform" @submit="rosterReport" v-model="valid" lazy-validation>
                <div class="add-contact">
                    <div class="row">
                        <div class="col-md-3 col-lg-3">
                            <div class="form-group">
                                <label for="" class="mb-2"> From Date
                                </label>
                                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :rules="fromDateRules" v-model="roster.from_date" label="Select from date" readonly v-bind="attrs" prepend-inner-icon="mdi-calendar" prepend-icon="" ref="fromdate" @click:prepend-inner="$refs.fromdate.$el.querySelector('input').click()" v-on="on" solo flat outlined dense></v-text-field>
                                    </template>
                                    <v-date-picker v-model="roster.from_date" @input="menu2 = false"></v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-3">
                            <div class="form-group">
                                <label for="" class="mb-2"> To Date
                                </label>
                                <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :rules="toDateRules" v-model="roster.to_date" label="Select to date" readonly v-bind="attrs" prepend-inner-icon="mdi-calendar" prepend-icon="" ref="todate" @click:prepend-inner="$refs.todate.$el.querySelector('input').click()" v-on="on" solo flat outlined dense></v-text-field>
                                    </template>
                                    <v-date-picker v-model="roster.to_date" @input="menu3 = false"></v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-3">
                            <div class="form-group">
                                <label for="" class="mb-2"> Employee List
                                </label>
                                <v-autocomplete :items="finalEmployees" v-model="roster.employee_id" :item-text="item=>item.name" :rules="employeeRules" :item-value="item=>item.employee_id" label="Select Employee" solo flat outlined dense></v-autocomplete>
                            </div>
                        </div>
    
                        <div class="col-md-2 col-lg-1">
                            <v-btn :loading="loading" type="submit" class="custom-btn btn-sm btn text-white">Submit</v-btn>
                        </div>
                    </div>
                </div>
            </v-form>
            <div class="table-responsive card mt-4" v-if="rosters">
                <div class="row">
                    <div class="float-right text-right">
                        <button @click="downloadFile()" class="btn btn-sm custom-btn mb-3 mr-2"><span><i class="fa fa-print"></i></span> Export</button>
                        <button @click="printDiv('print')" class="btn btn-sm custom-btn mb-3"><span><i class="fa fa-print"></i></span> Print</button>
                    </div>
                </div>
                <div class="table-header" id="print">
                    <table class="material-table mt-4 table"> 
                        <thead>
                            <tr class="text-center">
                                <th>Sl</th>
                                <th>Employee Id</th>
                                <th>Employee Name</th>
                                <th>Date</th>
                                <th>Start time</th>
                                <th>End Time</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="rosters">
                            <tr class="text-center" v-for="(roster,index) in rosters" :key="index">
                                <td> {{index + 1}} </td>
                                <td> {{roster.employee ? roster.employee.employee_id : '' }}</td>
                                <td> {{ roster.employee ? roster.employee.name : '' }}</td>
                                <td> {{ roster.date ? moment(roster.date).format("MMMM Do YYYY") : '-' }}</td>
                                <td> {{ roster.start_time ? moment(roster.start_time, "HH:mm").format("hh:mm A") : '-' }}</td>
                                <td> {{ roster.end_time ? moment(roster.end_time, "HH:mm").format("hh:mm A"): '-' }}</td>
                                <td>
                                    <p class="text-primary" v-if="(roster.status == 1)">Active</p>
                                    <p class="text-danger" v-if="(roster.status == 0)">De-active</p>
                                </td>
                                <td>
                                    <button class="data-btn mr-2"
                                    @click="e => editItem(roster.id, e)">
                                    <i class="fa fa-edit"></i>
                                     </button>
                                    <button class="data-btn"
                                        @click="e => deleteItem(roster.id, e)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
    
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr class="text-center">
                                <td colspan="8">
                                    No data Available
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import {
        mapActions,
        mapState
    } from "vuex";
    import DataTable from "vue-materialize-datatable";
    import moment from 'moment';
    import exportFromJSON from "export-from-json";
    export default {
        name: 'dashboard',
        data: () => ({
    
            // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            // menu: false,
            // modal: false,
            menu2: false,
            menu3: false,
            roster: {
                from_date: null,
                to_date: null,
                employee_id: null,
            },
    
            date: null,
            temp_attrs: [],
            rosters: [],
            valid: true,
            year: null,
            loading: false,
            isValidate: false,
            month: null,
            fixedSuccess: false,
            fixedError: false,
            attrs: [],
            reports: [],
            error: "",
    
            fromDateRules: [
                (v) => !!v || "From date is required",
            ],
            toDateRules: [
                (v) => !!v || "To date is required",
            ],
            employeeRules: [
                (v) => !!v || "Select Employee",
            ],
        }),
        components: {
            "datatable": DataTable
        },
    
        async created() {
            this.getEmployees();
            this.todayReport();
        },
        computed: {
            ...mapState('employee', ['employees']),
            finalEmployees() {
                var employees = this.employees
                employees.unshift({
                    employee_id: 'all',
                    name: 'All'
                })
                return employees
            }
        },
    
        methods: {
            ...mapActions('employee', ['getEmployees']),
            todayReport() {
                this.roster.employee_id = 'all';
                this.roster.from_date = moment(new Date()).format("YYYY-MM-DD")
                this.roster.to_date = moment(new Date()).format("YYYY-MM-DD")
                this.submitReport(1);
            },
            editItem(id,event)
            {
                this.$router.push({name:'EditRoaster', params: { id: id}}).catch(()=>{});
            },
		deleteItem(id, e) {
            this.$swal({
                title: "Delete Roaster",
                text: "Are you sure you want to delete this roaster?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Delete!"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
               
                axios
                    .delete("roaster/" +
                        id
                    )
                    .then((res) => {
                        // this.feedlist = res.data;
                        this.$swal('Successfully deleted');
                        this.getRoasters();
                    })
                    .catch((err) => {
                        console.log("Error", err);
                    });
                }
            });
        },
            downloadFile() {
                const data = this.rosters.map((attendance, sl) => {
                    return {
                        Sl: sl,
                        employee_id: roster.employee_id,
                        employee_name: roster.employee_name,
                        date: roster.date ? moment(roster.date).format("MMMM Do YYYY") : '-',
                        start_time: roster.start_time ? moment(roster.start_time, "HH:mm").format("hh:mm A") : '-',
                        end_time: roster.end_time ? moment(roster.end_time, "HH:mm").format("hh:mm A") : '-',
                        status: roster.status == 'A' ? 'Absent' : 'Present'
                    }
                });
                const fileName = "Attendance Report";
                const exportType = exportFromJSON.types.csv;
    
                if (data) exportFromJSON({
                    data,
                    fileName,
                    exportType
                });
            },
            rosterReport(event) {
                event.preventDefault();
                this.loading = true
                let isValidate = true
                this.error = "";
                this.$refs.rosterreportform.validate();
                this.$refs.rosterreportform.inputs.forEach((input) => {
                    if (input.hasError) {
                        isValidate = false;
                    }
                });
                if (!isValidate) {
                    this.loading = false
                    return;
                }
                this.submitReport();
    
            },
            submitReport(number = null) {
                this.$store.dispatch("roaster/RoasterReport", this.roster).then((response) => {
                    // this.$router.push("/employee").catch(()=>{})
                    if (response.data) {
                        this.loading = false,
                        this.fixedSuccess = (number == 1) ?  false : response.message;
                        this.rosters = response.data;
                    } else {
                        this.loading = false
                        this.fixedError = response.message
                    }
                }).catch(() => {
                    this.loading = false
                })
            },
    
            printDiv(divID) {
                var divContents = document.getElementById("print").innerHTML;
                var a = window.open('', '', '');
                a.document.write('<html><head><style type="text/css">');
                // a.document.write('<link rel="stylesheet" href="/assets/backend/css/bootstrap.min.css" type="text/css" />');
                a.document.write('body,td,th{font-family:Arial, Helvetica, sans-serif;font-size:13px;color:#000000}');
                a.document.write('.material-table td,.material-table th {border: 1px solid #e3ebf3; }');
                a.document.write('thead tr th {color: rgb(0 0 0);    font-size: 13px;    height: 45px !important;}');
                a.document.write('table{border-collapse:collapse; margin:0 auto;}');
                a.document.write('thead th{font-size:13px!important;}');
                a.document.write('table td, table tr td {    padding: 3px 2px !important;}');
                a.document.write('.bottom-line{position:fixed; transform: translateX(-50%); left:50%;bottom:0}');
                a.document.write('h3,h2,h1,p{text-align:center;}');
                a.document.write('</style></head><body> <h1>Jamalpur Central Hospital Ltd</h1><h2>Amlapara, Jamalpur</h2><h3>Attendence Sheet </h3>');
                a.document.write('<p>From date:- '+ moment(this.roster.from_date).format("DD MM YYYY") + ' To date:- '+ moment(this.roster.to_date).format("DD MM YYYY") +'</p>');
                a.document.write(divContents);
                a.document.write('<p class="bottom-line"> This report is system generated, no signiture needed </p>');
                a.document.write('</body></html>');
                a.document.close();
                a.print();
                // document.body.innerHTML = oldPage;
                // location.reload(this.$router.push("/attendance_report").catch(() => {}));
            }
        },
    };
    </script>
    
    <style>
    /* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
    </style>
    