<template>
  <div class="container-fluid">
    <div class="container-wrappero">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/department">Department</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Add Department
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- content-page-header end -->
      <div class="content-body mb-4">
        <div class="add-contact">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Department Details
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <v-form
                    @submit="saveDepartment"
                    v-model="valid"
                    ref="departmentform"
                    lazy-validation
                  >
                    <div class="row g-3">
                      <div class="col-md-6">
                        <label for="inputEmail4" class="form-label"
                          >Department Name <span class="text-danger">*</span></label
                        >
                        <v-text-field
                          placeholder="Enter Department Name"
                          type="text"
                          v-model="department.name"
                          :rules="nameRules"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail4" class="form-label"
                          >Status <span class="text-danger">*</span></label
                        >
                        <v-select
                        :items="status_items"
                        v-model="department.status"
                        :item-text="(item) => item.name"
                        :item-value="(item) => item.value"
                        outlined
                        dense
                        ></v-select>
                      </div>
                      <div class="col-md-12">
                        <label
                          for="FullName 
                                                            "
                          class="form-label"
                          >Department Description
                        </label>
                        <v-textarea
                          placeholder="Enter Description"
                          label=""
                          type="email"
                          v-model="department.description"
                          outlined
                          dense
                        ></v-textarea>
                      </div>
                    </div>
                    <div class="submit-section">
                      <div class="">
                        <v-btn
                          class="mr-4 custom-btn"
                          type="button"
                          @click="resetButton()"
                          color="error"
                        >
                          Reset
                        </v-btn>
                      </div>
                      <div class="">
                        <v-btn
                          class="custom-btn f-right"
                          color="blue darken-4"
                          type="submit"
                          :loading="loading"
                          dark
                        >
                          Submit
                        </v-btn>
                      </div>
                    </div>
                  </v-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { de } from "vue-materialize-datatable/src/locales";
import { mapState, mapActions } from "vuex";
export default {
  name: "AddContact",

  data: () => ({
    department: {
      id: "",
      name: "",
      description: "",
      status: "",
    },
    loading: false,
    valid: true,
    error: "",
    status_items: [
      { id: 0, name: "De-active", value: 0 },
      { id: 1, name: "Active", value: 1 },
    ],
    requiredRules: [
      (v) => !!v || "Field is required",
    ],
    nameRules: [(v) => !!v || "Enter your department name"],
  }),
  async created() {
    if (this.$route.params.id) {
      this.getDepartmentDetails();
    }
  },
  computed: {},
  methods: {
    saveDepartment(event) {
      event.preventDefault();
      this.loading = true;
      let isValidate = true;
      this.error = "";
      this.$refs.departmentform.validate();
      this.$refs.departmentform.inputs.forEach((input) => {
        if (input.hasError) {
          isValidate = false;
        }
      });
      if (!isValidate) {
        this.loading = false
        return;
      }
      if (this.department.id == "") {
        this.$store
          .dispatch("department/AddDepartment", this.department)
          .then((response) => {
            this.$router.push("/department").catch(() => {});
          })
          .catch(() => {});
      } else {
        this.$store
          .dispatch("department/UpdateDepartment", this.department)
          .then((response) => {
            this.$router.push("/department").catch(() => {});
          })
          .catch(() => {});
      }
    },
    getDepartmentDetails() {
      this.$store
        .dispatch("department/Departmentdetails", this.$route.params.id)
        .then((res) => {
          if (res) {
            (this.department.id = res.id),
              (this.department.name = res.name),
              (this.department.status = res.status),
              (this.department.description = res.description);
          }
        });
    },
    resetButton() {
      this.$refs.departmentform ? this.$refs.departmentform.reset() : '';
    },
  },
};
</script>
<style scoped>
.submit-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
