<template>
  <div class="container-fluid">
    <div class="container-wrappero">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/useradmin">User Admin</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Profile
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- content-page-header end -->
      <div class="content-body mb-4">
        <v-form
          @submit.prevent="updateUserProfile()"
          v-model="valid"
          ref="userProfileform"
          lazy-validation
        >
          <div v-if="old_pass_error">
            <v-alert dense text type="error" dismissible v-if="old_pass_error">
              {{ old_pass_error }}
            </v-alert>
          </div>
          <v-alert class="mt-4" dense text type="success" dismissible v-if="fixedSuccess">
                        {{fixedSuccess}}
            </v-alert>
          <div class="add-contact row">
            <div class="col-md-6">
              <div class="accordion accordion-main" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Admin Profile
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="row g-3">
                        <div class="col-md-12 padding mt-0">
                          <div
                            class="
                              employee-image
                              img-thumbnail
                              text-center
                              mb-3
                            "
                          >
                            <img
                              v-if="emp_image"
                              class="img-thumbnail"
                              :src="emp_image"
                              alt=""
                            />
                          </div>
                          <div class="custom-group">
                            <label for="inputEmail4" class="form-label"
                              >Image *</label
                            >
                            <v-file-input
                              accept="image/*"
                              label="File input"
                              outlined
                              solo
                              flat
                              @change="previewImage"
                              v-model="main_image"
                              dense
                            ></v-file-input>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-2">
                          <div class="custom-group">
                            <label class="form-label">Name *</label>
                            <v-text-field
                              placeholder="Enter Employee Name"
                              type="text"
                              v-model="profile.name"
                              :rules="requiredRules"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="accordion accordion-main mb-3" id="accordionCompany">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingCompany">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      Details
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingCompany"
                    data-bs-parent="#accordionCompany"
                  >
                    <div class="accordion-body">
                      <div class="row g-3">
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Email *</label>
                            <v-text-field
                              placeholder="Email"
                              type="email"
                              v-model="profile.email"
                              :rules="emailRules"
                              outlined
                              dense
                            >
                            </v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Phone *</label>
                            <v-text-field
                              placeholder="Phone"
                              type="text"
                              v-model="profile.phone"
                              :rules="phoneRules"
                              outlined
                              dense
                            >
                            </v-text-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion accordion-main" id="accordionAccount">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingAccount">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseAccount"
                      aria-expanded="true"
                      aria-controls="collapseAccount"
                    >
                      Update User Password
                    </button>
                  </h2>
                  <div
                    id="collapseAccount"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingAccount"
                    data-bs-parent="#accordionAccount"
                  >
                    <div class="accordion-body">
                      <div class="row g-3">
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Old Password *</label>
                            <v-text-field
                              placeholder="Enter old password"
                              type="password"
                              v-model="profile.old_password"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">New Password *</label>
                            <v-text-field
                              placeholder="Enter new password"
                              type="password"
                              v-model="profile.new_password"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12 padding mt-0">
                          <div class="custom-group">
                            <label class="form-label">Confirm Password *</label>
                            <v-text-field
                              placeholder="enter your branch"
                              type="password"
                              v-model="profile.comfirm_password"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section">
              <div class="">
                <v-btn
                  class="mr-4 custom-btn"
                  type="button"
                  @click="resetButton()"
                  color="error"
                >
                  Reset
                </v-btn>
              </div>
              <div class="">
                <v-btn
                  class="custom-btn f-right"
                  color="blue darken-4"
                  type="submit"
                  :loading="loading"
                  dark
                >
                  Submit
                </v-btn>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>
    
    <script>
import { mapState, mapActions } from "vuex";
export default {
  name: "AddEmployee",

  data: () => ({
    user: {},
    fixedSuccess: false,
    profile: {
      id: "",
      name: "",
      email: "",
      phone: "",
      password: "",
      image: "",
      comfirm_password: "",
      new_password: "",
      old_password: "",
    },
    main_image: [],
    emp_image: null,
    loading: false,
    old_pass_error: null,
    valid: true,
    error: "",
    previewImageValidation: "",
    requiredRules: [(v) => !!v || "Field is required"],
    emailRules: [
      (v) => !!v || "Email is required",
      (v) =>
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Enter valid email address",
    ],
    phoneRules: [
      (v) => !!v || "Phone is required",
      (v) => v.length >= 8 || "Phone number requires at least 8 digit",
      (v) => v.length < 12 || "Phone number cannot more than 11 digit",
    ],
  }),
  async created() {
    await this.getUser();
    this.setUser();
  },
  computed: {},
  methods: {
    getUser() {
      this.user = JSON.parse(localStorage.getItem("user_data"));
      console.log(
        "ata user data",
        this.user,
        localStorage.getItem("user_data")
      );
    },

    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.emp_image = e.target.result;
        this.profile.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    setUser() {
      if (this.user) {
        let user = this.user;
        (this.profile.id = user.id),
          (this.profile.name = user.name),
          (this.profile.email = user.email),
          (this.profile.phone = user.phone),
          (this.profile.old_password = user.password),
          (this.emp_image = user.image);
      }
    },
    previewImage(file) {
      if (!file) {
        this.emp_image = null;
        return;
      }
      this.createImage(file);
    },

    resetButton() {
      this.$refs.userProfileform.reset();
    },

    updateUserProfile() {
      axios
        .put("user-profile-update/" + this.$route.params.id, this.profile)
        .then((respose) => {
          // this.$router.push("/").catch(()=>{})
          let data = respose.data;
          console.log("response user data peyechi", respose.data);
          if (data.data) {
            localStorage.setItem(
              "user_data",
              JSON.stringify(respose.data.data)
            );
            this.fixedSuccess = data.message
            this.getUser();
            window.location.reload(true);
          } else {
            this.old_pass_error = data.message;
            console.log("error peyechi", data.message);
            console.log("error peyechi message", this.old_pass_error);
          }
        })
        .catch(() => {})
        .catch((err) => {
          console.log("Error", err);
        });
    },
  },
};
</script>
    <style>
.accordion-main {
  border: 1px solid #ddd;
  margin-right: 2px;
  padding: 10px;
}
.add-contact .accordion-item {
  margin-bottom: 0px;
}
.employee-image {
  width: 200px;
  height: 200px;
  text-align: center;
  margin: 0 auto;
}
.employee-image img {
  height: 100%;
  object-fit: cover;
}
.submit-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
    