<template>
<div class="container-fluid">
    <div class="container-wrappero">
        <div class="content-page-header mb-4">
            <div class="breadcrumb-header">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb content_shadow">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard">Dashboard</router-link>
                        </li>
                        <li class="breadcrumb-item">
                            <router-link to="/salary_setting">salary setting</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Add Salary Setting </li>
                    </ol>
                </nav>
            </div>
        </div>
        <!-- content-page-header end -->
        <div class="content-body  mb-4">
            <div class="add-contact">
                <v-form class="row g-3" @submit.prevent="submitSetting()" v-model="valid" ref="salarySettingform">
                    <div class="row">
                        <div class="col-md-6 padding">
                            <label for="Addcontact" class="form-label">Select Type <span class="text-danger">*</span>
                            </label>
                            <v-select :items="types" v-model="settingSalary.setting_type" :item-text="item=>item.name" :rules="typeRules" :item-value="item=>item.id" label="Select Type" solo flat outlined dense></v-select>
                        </div>
                        <div class="col-md-6 padding">
                            <label for="Addcontact" class="form-label">Select Salary Arrange Type <span class="text-danger">*</span>
                            </label>
                            <v-select :items="basicTypes" v-model="settingSalary.basic_type" :item-text="item=>item.name" :rules="basicTypeRules" :item-value="item=>item.id" label="Select Type" solo flat outlined dense></v-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 padding">
                            <label for="Addcontact" class="form-label">Medical Allowance</label>
                            <v-text-field name="input-7-1" placeholder="Medical Allowance" v-model="settingSalary.medical_allowance" outlined dense></v-text-field>
                        </div>
                        <div class="col-md-3 padding">
                            <label for="Addcontact" class="form-label">Provident Found</label>
                            <v-text-field name="input-7-1" placeholder="Provident Found" v-model="settingSalary.provident_found" outlined dense></v-text-field>
                        </div>
                        <div class="col-md-3 padding">
                            <label for="Addcontact" class="form-label">House Rent</label>
                            <v-text-field name="input-7-1" placeholder="House Rent" v-model="settingSalary.house_rent" outlined dense></v-text-field>
                        </div>
                        <div class="col-md-3 padding">
                            <label for="Addcontact" class="form-label">Incentive</label>
                            <v-text-field name="input-7-1" placeholder="Incentive" v-model="settingSalary.incentive" outlined dense></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 padding">
                            <v-btn class="mr-4" type="button" @click="resetButton()" color="error">
                                Reset
                            </v-btn>
                        </div>
                        <div class="col-md-6 padding">
                            <v-btn color="blue darken-4" class="f-right" type="submit" :loading="loading" dark>
                                Submit
                            </v-btn>
                        </div>

                    </div>

                </v-form>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    mapState,
    mapActions
} from 'vuex';
export default {
    name: 'addDesignation',

    data: () => ({
        valid: true,
        settingSalary: {
            id: '',
            employee_id: 0,
            setting_type: '',
            basic_type: '',
            basic: 0,
            medical_allowance: '',
            provident_found: '',
            house_rent: '',
            incentive: ''
        },

        types: [{
                id: 1,
                name: "Amount"
            },
            {
                id: 2,
                name: "Percentage"
            },
        ],
        basicTypes: [{
                id: 1,
                name: "With Basic Salary"
            },
            {
                id: 2,
                name: "Without Basic Salary"
            },
        ],

        loading: false,
        typeRules: [
            v => !!v || 'Type is required',
        ],
        basicTypeRules: [
            v => !!v || 'Basic type is required',
        ],

    }),
    async created() {
        // this.getDepartments();
        if (this.$route.params.id) {
            this.getSettingDetails();
        }
    },
    computed: {
        ...mapState('department', ['departments'])

    },
    methods: {
        ...mapActions('department', ['getDepartments']),
        resetButton() {
            this.$refs.salarySettingform.reset();
        },
        submitSetting() {
            if (this.settingSalary.id == '') {
                this.loading = true
                if (this.$refs.salarySettingform.validate()) {
                    this.$store
                        .dispatch("salary_setting/AddSalarySetting", this.settingSalary)
                        .then((response) => {
                            this.$router.push('/salary_setting')
                            this.$refs.designationform.reset();
                        })
                        .catch((error) => {
                            // console.log(error.response.data.errors);
                            this.loading = false
                        });
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = true
                if (this.$refs.salarySettingform.validate()) {
                    this.$store
                        .dispatch("salary_setting/UpdateSalarySetting", this.settingSalary)
                        .then((response) => {
                            this.$router.push('/salary_setting')
                            this.$refs.designationform.reset();
                        })
                        .catch((error) => {
                            // console.log(error.response.data.errors);
                            this.loading = false
                        });
                } else {
                    this.loading = false;
                }
            }

        },

        getSettingDetails() {
            this.$store.dispatch("salary_setting/Settingdetails", this.$route.params.id).then((res) => {
                if (res) {
                    this.settingSalary.id = res.id,
                        this.settingSalary.setting_type = res.type,
                        this.settingSalary.basic_type = res.with_without_basic,
                        this.settingSalary.basic = res.basic,
                        this.settingSalary.medical_allowance = res.medical_allowance,
                        this.settingSalary.provident_found = res.provident_found,
                        this.settingSalary.house_rent = res.house_rent,
                        this.settingSalary.incentive = res.incentive
                }
            })
        },


    }
}
</script>
