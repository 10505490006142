<template>
  <div class="container-fluid">
    <div class="container-wrappero">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/leave">Leave Application</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ leave.id != "" ? "Edit Leave" : "Add Leave" }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- content-page-header end -->
      <div class="content-body mb-4">
        <div class="add-contact">
          <div class="accordion-body">
            <v-form
              @submit="saveLeave"
              v-model="valid"
              ref="leaveform"
              lazy-validation
            >
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="inputEmail4" class="form-label"
                    >Select Employee <span class="text-danger">*</span></label
                  >
                  <v-autocomplete
                    :items="employees"
                    v-model="leave.employee_id"
                    :item-text="
                      (item) => item.name + ' (' + item.employee_id + ')'
                    "
                    :item-value="(item) => item.id"
                    :rules="employeeRules"
                    outlined
                    dense
                  ></v-autocomplete>
                </div>
                <div class="col-md-3">
                  <label for="inputEmail4" class="form-label"
                    >From Date <span class="text-danger">*</span></label
                  >
                  <v-text-field
                    placeholder="Enter Leave From Date"
                    type="date"
                    v-model="leave.from_date"
                    :rules="dateRules"
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-md-3">
                  <label for="inputEmail4" class="form-label">To Date <span class="text-danger">*</span></label>
                  <v-text-field
                    placeholder="Enter Leave To Date"
                    type="date"
                    v-model="leave.to_date"
                    :rules="dateRules"
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-md-12">
                  <label class="form-label">Reason <span class="text-danger">*</span></label>
                  <v-textarea
                    placeholder="Enter Leave Reason"
                    type="text"
                    rows="2"
                    v-model="leave.reason"
                    :rules="reasonRules"
                    outlined
                    dense
                  ></v-textarea>
                </div>
                <!-- <div class="col-md-4">
                  <label for="inputEmail4" class="form-label">File Upload</label>
                  <v-text-field class="file-upload"
                    placeholder="Upload file"
                    type="file"
                    v-model="leave.file"
                    outlined
                    dense
                  ></v-text-field>
                </div> -->
                <div class="col-md-4">
                  <label for="inputEmail4" class="form-label"
                    >Leave Type <span class="text-danger">*</span></label
                  >
                  <v-select
                    :items="leave_type_items"
                    v-model="leave.leave_type"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.value"
                    :rules="requiredRules"
                    outlined
                    dense
                  ></v-select>
                </div>
                <div class="col-md-4">
                  <label for="inputEmail4" class="form-label">Status <span class="text-danger">*</span></label>
                  <v-select
                    :items="status_items"
                    v-model="leave.status"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.value"
                    :rules="requiredRules"
                    outlined
                    dense
                  ></v-select>
                </div>
              </div>
              <div class="submit-section">
                <div class="">
                  <v-btn
                    class="mr-4 custom-btn"
                    type="button"
                    @click="resetButton()"
                    color="error"
                  >
                    Reset
                  </v-btn>
                </div>
                <div class="">
                  <v-btn
                    class="custom-btn f-right"
                    color="blue darken-4"
                    type="submit"
                    :loading="loading"
                    dark
                  >
                    Submit
                  </v-btn>
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { de } from "vue-materialize-datatable/src/locales";
import { mapState, mapActions } from "vuex";
export default {
  name: "AddLeave",

  data: () => ({
    leave: {
      id: "",
      employee_id: "",
      from_date: "",
      to_date: "",
      leave_type: "",
      reason: "",
      file: "",
      status: "",
    },
    loading: false,
    valid: true,
    error: "",
    leave_type_items: [
      { id: 0, name: "Sick", value: "sick" },
      { id: 1, name: "Casual", value: "casual" },
      // { id: 2, name: "half day", value: "half day" },
      { id: 3, name: "Maternity", value: "maternity" },
      { id: 4, name: "Unpaid", value: "unpaid" },
      { id: 5, name: "Other", value: "other" },
    ],
    status_items: [
      { id: 0, name: "Pending", value: "pending" },
      { id: 1, name: "Approve", value: "approve" },
      { id: 2, name: "Rejected", value: "rejected" },
    ],
    requiredRules: [(v) => !!v || "Field is required"],
    dateRules: [(v) => !!v || "Date is required"],
    reasonRules: [(v) => !!v || "Reason is required"],
    employeeRules: [(v) => !!v || "Employee is required"],
  }),
  async created() {
    this.getEmployees();
    if (this.$route.params.id) {
      this.getLeaveDetails();
    }
  },
  computed: {
    ...mapState("employee", ["employees"]),
  },
  methods: {
    ...mapActions("employee", ["getEmployees"]),
    saveLeave(event) {
      event.preventDefault();
      this.loading = true;
      let isValidate = true;
      this.error = "";
      this.$refs.leaveform.validate();
      this.$refs.leaveform.inputs.forEach((input) => {
        if (input.hasError) {
          isValidate = false;
        }
      });
      if (!isValidate) {
        this.loading = false;
        return;
      }
      if (this.leave.id == "") {
        this.$store
          .dispatch("leave/AddLeave", this.leave)
          .then((response) => {
            this.$router.push("/leave").catch(() => {});
          })
          .catch(() => {});
      } else {
        this.$store
          .dispatch("leave/UpdateLeave", this.leave)
          .then(() => {
            this.$router.push("/leave").catch(() => {});
          })
          .catch(() => {});
      }
    },
    getLeaveDetails() {
      this.$store
        .dispatch("leave/Leavedetails", this.$route.params.id)
        .then((res) => {
          if (res) {
            (this.leave.id = res.id),
              (this.leave.employee_id = res.employee_id),
              (this.leave.from_date = res.from_date),
              (this.leave.to_date = res.to_date),
              (this.leave.leave_type = res.leave_type),
              (this.leave.status = res.status),
              (this.leave.reason = res.reason);
              (this.leave.file = res.file);
          }
        });
    },
    resetButton() {
      this.$refs.leaveform.reset();
    },
  },
};
</script>
<style>
  .submit-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .file-upload input{
    padding: 0px !important;
  }
</style>