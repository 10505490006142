<template>
    <Homepage />
</template>

<script>
import Homepage from "../components/homepage"
export default {
    name: "Home",
    components: {
        Homepage
    }
}

</script>

<style>

</style>