<template>
    <v-main id="master_app">
        <div id="wrapper">
          <core-sidebar :sidebar="sidebar" @toggleSidebar="toggleSidebar" />
          <div class="w-100" id="content_wrapper">
            <core-header :sidebar="sidebar" @toggleSidebar="toggleSidebar" />
              <core-view />
          </div>
            <core-footer />
        </div>
    </v-main>
    </template>
    
    <script>
    require('@/assets/backend/css/all.min.css');
    require('@/assets/backend/css/bootstrap.min.css');
    require('@/assets/backend/css/default.css');
    require('@/assets/backend/css/style.css');
    require('@/assets/backend/css/responsive.css');

    // js backend
    import('@/assets/backend/js/jquery-3.6.0.min.js');
    require('@/assets/backend/js/bootstrap.min.js');
    import('@/assets/backend/js/main.js');
    
    export default {
      name: "App",
      data: () => ({
        sidebar: false,
      }),
      components: {
        CoreFooter: () => import("@/components/core/footer"),
        CoreSidebar: () => import("@/components/core/sidebar"),
        CoreHeader: () => import("@/components/core/topbar"),
        CoreView: () => import("@/components/core/coreView"),
         
      },
      mounted(){
          this.checkUserLogin()
      },
      methods: {
          checkUserLogin() {
          // send request to check if the user is logged otherwise redirect him to login screen
          if (
            localStorage.getItem("auth_token") !== null &&
            localStorage.getItem("auth_token") !== undefined
          ) {
            axios
              .get("check-login", {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
              })
              .then((response) => {
                if (response.data.success != 1) {
                  localStorage.removeItem("auth_token");
                  localStorage.removeItem("is_authenticated");
                  localStorage.removeItem("user_data");
                  this.$router.push("/admin");
                }
              })
              .catch((err) => {
                if (err) {
                  localStorage.removeItem("auth_token");
                  localStorage.removeItem("is_authenticated");
                  localStorage.removeItem("user_data");
                  this.$router.push("/admin");
                }
              });
          }
        },
        toggleSidebar(){
          this.sidebar = !this.sidebar
        }
      }
    };
    </script>
    
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
    #master_app #app{
        font-family: 'Open Sans', sans-serif;
    }
    .v-application ul,
    .v-application ol {
        padding-left: 0px !important;
    }
    .table>:not(:last-child)>:last-child>* {
      border-bottom-color: #dee2e6!important;
    }
    .material-table thead tr th {
      vertical-align: middle;
    }
    </style>
        