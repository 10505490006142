<template>
    <div class="container-fluid ">
        <div class="container-wrappero dashboard-w">
            <div class="content-page-header mb-4 ">
                <div class="card ">
                    <div class="card-body ">
                        <h4 class="page-title ">
                            <router-link class="nav-link" to="/dashboard">
                            <a ><i class="fas fa-th-large "></i> <span>Dashboard</span> </a>
                            </router-link>
                        </h4>
                    </div>
                    <!-- end card body-->
                </div>
            </div>
            <div class="content-body mb-4 ">
                <div class="row ">
                    <!-- Amounts -->
                    <div class="col-xl-3 col-md-6 mb-4 ">
                        <div class="card border-left-warning shadow h-100 py-2 ">
                            <div class="card-body ">
                                <div class="row no-gutters align-items-center ">
                                    <div class="col me-2 ">
                                        <div class="d-flex align-items-center ">
                                            <div class="me-3 ">
                                                <i class="fas fa-users fa-2x text-gray-300 "></i>
                                            </div>
                                            <div>
                                                <div class="text-xs font-weight-bold text-success text-uppercase mb-1 ">
                                                    Total Employees</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800 ">{{report ? report.total_employees: 0}}</div>
    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto ">
    
                                        <!-- Nav Item - User Information -->
                                        <div class="crud ">
    
                                            <button role="button">
                                                <div class="dot-item ">
                                                    <div class="dot1 "></div>
                                                    <div class="dot2 "></div>
                                                    <div class="dot3 "></div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Campaign  -->
                    <div class="col-xl-3 col-md-6 mb-4 ">
                        <div class="card border-left-primary shadow h-100 py-2 ">
                            <div class="card-body ">
                                <div class="row no-gutters align-items-center ">
                                    <div class="col me-2 ">
                                        <div class="d-flex align-items-center ">
                                            <div class="me-3 ">
                                                <i class="fas fa-thin fa-arrows-rotate fa-2x text-gray-300 "></i>
                                            </div>
                                            <div>
                                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1 ">
                                                    Total Rosters of this month </div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800 ">{{report ? report.total_month_roaster: 0}}</div>
    
                                            </div>
    
                                        </div>
    
                                    </div>
                                    <div class="col-auto ">
    
                                        <!-- Nav Item - User Information -->
                                        <div class="col-auto ">
    
                                            <!-- Nav Item - User Information -->
                                            <div class="crud ">
    
                                                <button role="button">
                                                    <div class="dot-item ">
                                                        <div class="dot1 "></div>
                                                        <div class="dot2 "></div>
                                                        <div class="dot3 "></div>
                                                    </div>
                                                </button>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Customer  -->
                    <div class="col-xl-3 col-md-6 mb-4 ">
                        <div class="card border-left-info shadow h-100 py-2 ">
                            <div class="card-body ">
                                <div class="row no-gutters align-items-center ">
                                    <div class="col me-2 ">
                                        <div class="d-flex align-items-center ">
                                            <div class="me-3 ">
                                                <i class="fas fa-thin fa-clock-rotate-left fa-2x text-gray-300"></i>
                                            </div>
                                            <div>
                                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1 ">
                                                    Total Rosters of Today</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800 ">{{report ? report.total_day_roaster: 0}}</div>
    
                                            </div>
    
                                        </div>
    
                                    </div>
                                    <div class="col-auto ">
    
                                        <!-- Nav Item - User Information -->
                                        <div class="col-auto ">
    
                                            <!-- Nav Item - User Information -->
                                            <div class="crud ">
    
                                                <button role="button">
                                                    <div class="dot-item ">
                                                        <div class="dot1 "></div>
                                                        <div class="dot2 "></div>
                                                        <div class="dot3 "></div>
                                                    </div>
                                                </button>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product  -->
                    <div class="col-xl-3 col-md-6 mb-4 ">
                        <div class="card border-left-success shadow h-100 py-2 ">
                            <div class="card-body ">
                                <div class="row no-gutters align-items-center ">
                                    <div class="col me-2 ">
                                        <div class="d-flex align-items-center ">
                                            <div class="me-3 ">
                                                <i class="fab fa-product-hunt fa-2x text-gray-300 "></i>
                                            </div>
                                            <div>
                                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1 ">
                                                    Total Present Today</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800 ">{{report ? report.present_today: 0}}</div>
    
                                            </div>
    
                                        </div>
    
                                    </div>
                                    <div class="col-auto ">
    
                                        <!-- Nav Item - User Information -->
                                        <div class="col-auto ">
    
                                            <!-- Nav Item - User Information -->
                                            <div class="crud ">
                                                <button role="button">
                                                    <div class="dot-item ">
                                                        <div class="dot1 "></div>
                                                        <div class="dot2 "></div>
                                                        <div class="dot3 "></div>
                                                    </div>
                                                </button>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
    
            <!-- -->
        </div>
    
    </div>
    </template>

<script>
import { mapState,mapActions } from 'vuex';
export default {
    computed:{
        ...mapState('useradmin', ['report']),
    },
    async created(){
        this.getDashboardReport();
    },
    methods:{
        ...mapActions('useradmin', ['getDashboardReport']),
    }
}
</script>

<style>

</style>
