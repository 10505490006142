export default {
    namespaced: true,
  
    state: () => ({
      leaves: []
    }),
  
    getters: {
      leaves(state){
        return state.leaves
      }
    },
  
    mutations: {
      populateLeave(state, newLeave){
        state.leaves = newLeave;
      },

      saveLeave(state,newLeave)
      {
        state.leaves.push(newLeave);
      },
      updateLeave(state,newLeave)
      {
       
      }
    },
  
    actions: {
      async getLeaves({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("leave_application")
          .then(res => {
            console.log("Leave Application Data::: ",res.data.data)
            resolve(res);
            commit("populateLeave",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async AddLeave({ commit },apiArg){
        return new Promise((resolve, reject) => {
          const accessToken = localStorage.getItem("auth_token");
          if (accessToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
          }
          axios.post("leave_application",apiArg)
          .then(res => {
            console.log("Leave application Data::: ",res.data.data)
            resolve(res);
            commit("saveLeave",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async UpdateLeave({ commit },apiArg){
        return new Promise((resolve, reject) => {
          const accessToken = localStorage.getItem("auth_token");
          if (accessToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
          }
          axios.put("leave_application/"+apiArg.id,apiArg)
          .then(res => {
            console.log("Leave Application Data::: ",res.data.data)
            resolve(res);
            commit("updateLeave",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async Leavedetails(ctx,apiArg){
        let isSuccess = false
        await axios.get("leave_application/"+apiArg+"/edit").then(response => {
          isSuccess = response.data.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
        },
    }
  };