<template>
    <div class="container-fluid">
        <div class="container-wrapper">
            <div class="row">
			<div class="float-right text-right">
				
				<button @click="AddRoasterSwap()" class="btn btn-sm custom-btn mb-1"> Swap</button>
			</div>
		</div>
            <v-alert class="mt-4" dense text type="success" dismissible v-if="fixedSuccess">
                        {{fixedSuccess}}
            </v-alert>
            <v-alert class="mt-4" dense text type="error" dismissible v-if="fixedError">
                        {{fixedError}}
            </v-alert>
            <div class="card mt-4">
                <div class="table-header table-responsive ">
                    <table class="material-table mt-4 table ">
                        <thead>
                            <tr class="text-center">
                                <th>Select</th>
                                <th>Employee Id</th>
                                <th>Employee</th>
                                <th>Date</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                            </tr>
                        </thead>
                        <tbody v-if="roasters.length">
                           
                                <tr class="text-center" v-for="(roaster,index) in roasters" :key="index">
                                    <td> <input type="checkbox" v-model="roaster_check" :value="roaster.id" @click="addSwap" /> </td>
                                    <td> {{roaster.employee ? roaster.employee.employee_id : ''}}</td>
                                    <td> {{roaster.employee ? roaster.employee.name : ''}}</td>
                                    <td> {{ roaster.date ? (moment(roaster.date).format("Do MMM YYYY")) : '' }} </td>
                                    <td> {{roaster.start_time ? moment(roaster.start_time, "HH:mm").format("hh:mm A") : ''}} </td>
                                    <td> {{roaster.end_time ? moment(roaster.end_time, "HH:mm").format("hh:mm A") : ''}} </td>
                                </tr>
                            
    
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    
    </div>
</template>
    
    <script>
    import {mapActions,mapState} from "vuex";
    import moment from 'moment';
    import DataTable from "vue-materialize-datatable";
    export default {
        name: 'Dashboard',
    
        data: () => ({
            fixedSuccess: false,
            fixedError: false,
            checkedId: [],
            roaster_check: [],
            tableColumns1: [
                {
                    label: "Select",
                    field: "check",
                    numeric: false,
                    html: '<input>'
                },
                {
                    label: "Sl",
                    field: "sno",
                    numeric: false,
                    html: false
                },
                {
                    label: "Employee Id",
                    field: "employee_id",
                    numeric: false,
                    html: false
                },
                {
                    label: "Employee Name",
                    field: "employee",
                    numeric: false,
                    html: false
                },
                {
                    label: "Start Time",
                    field: "start_time",
                    numeric: false,
                    html: false
                },
                {
                    label: "Date",
                    field: "date",
                    numeric: false,
                    html: false
                },
                {
                    label: "End Time",
                    field: "end_time",
                    numeric: false,
                    html: false
                }
            ],
            // tableRows2: [
            // 	{
            // 		charName: "Abu",
            // 		firstAppearance: "Alladin (1992)",
            // 		createdBy: "Joe Grant",
            // 		voicedBy: "Frank Welker"
            // 	},
            // 	{
            // 		charName: "Magic Carpet",
            // 		firstAppearance: "Alladin (1992)",
            // 		createdBy: "Randy Cartwright",
            // 		voicedBy: "N/A"
            // 	},
            // 	{
            // 		charName: "The Sultan",
            // 		firstAppearance: "Alladin (1992)",
            // 		createdBy: "Navid Negahban",
            // 		voicedBy: "Douglas Seale"
            // 	}
            // ],
    
        }),
        components: {
            "datatable": DataTable
        },
    
        async created() {
           this.getRoasters();
        },
        computed: {
            ...mapState('roaster',['roasters']),
            finalRoasters()
            {
                return this.roasters.length > 0 ? this.roasters.map((roaster,sl)=>({...roaster,sno: sl + 1,check: '<input type="checkbox">',employee: (roaster.employee ? roaster.employee.name : ''),start_time: (moment(roaster.start_time, "HH:mm").format("hh:mm A")),date: (moment(roaster.date).format("Do MMM YYYY")),end_time: (moment(roaster.end_time, "HH:mm").format("hh:mm A"))})) : [];
            }
        },
    
        methods: { 
            ...mapActions('roaster',['getRoasters']),
            AddRoaster()
            {
                this.$router.push('/add_roaster').catch(()=>{});
            },
            AddRoasterSwap()
            {
                let data = {
                    roaster: this.checkedId
                }
                this.$store.dispatch("roaster/swapRoaster",data).then((response)=>{
                    if(response.data.data){

                        this.fixedSuccess = response.data.message
                        this.roaster_check = []
                        this.checkedId = []
                    }
                    else{
                        this.fixedError = response.data.message
                        this.roaster_check = []
                    }
                    this.getRoasters();
                }).catch(()=>{})
            },
            importCsvfile(event){
                this.fixedSuccess = null
                let files = event.target.files[0];
                console.log("file peyechi", files)
                let data = new FormData();
                data.append('file',files)
                this.$store.dispatch("roaster/importRoasterFile",data).then((response)=>{
                    this.$refs.fileupload.value=null;
                    this.fixedSuccess = response.data.message
                    this.getRoasters();
                    this.$router.push('/roaster_management').catch(()=>{})
                }).catch(()=>{})
            },
            addSwap(event)
            {
                let value = event.target.value
                let check_index = this.checkedId.indexOf(value)
                if(check_index !== -1 && this.checkedId.length){
                    this.checkedId.splice(check_index,1)
                    
                }
                else{
                    if(this.checkedId.length > 1){
                        alert('for roaster swap you cannot select more than 2 items')
                        event.preventDefault()
                        return;
                    }
                    this.checkedId.push(value)
                }
                console.log('roaster check', this.roaster_check, this.checkedId)
                
            },
            editItem(props,event)
            {
                this.$router.push({name:'EditRoaster', params: { id: props.id}}).catch(()=>{});
            },
            deleteItem(props, e) {
                this.$swal({
                    title: "Delete Roaster",
                    text: "Are you sure you want to delete this roaster?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Delete!"
                }).then((result) => { // <--
                    if (result.value) { // <-- if confirmed
                   
                    axios
                        .delete("roaster/" +
                            props.id
                        )
                        .then((res) => {
                            // this.feedlist = res.data;
                            this.$swal('Successfully deleted');
                            this.getRoasters();
                        })
                        .catch((err) => {
                            console.log("Error", err);
                        });
                    }
                });
            }
        },
    };
    </script>
    
    <style>
       /* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
       .data-btn{
            background-color: #607d8b!important;
            color: #fff;
            padding: 5px 10px 5px 8px!important;
       }
       .custom-btn{
        background-color: #607d8b!important;
       }
       .input-wrapper {
                    position: relative;
                }
                
                .input-wrapper [type=file] {
                    height: 0;
                    overflow: hidden;
                    width: 0;
                }
                
                .input-wrapper [type=file]+label {
    
                    border: none;
            
                    color: #fff;
                    cursor: pointer;
                    display: inline-block;
                    outline: none;
                    position: relative;
                    transition: all 0.3s;
                    vertical-align: middle;
                }


               
              .table-responsive   table td,
                .table-responsive  table th,
                .table-responsive   table tr td {
                padding: 0 15px !important;
                
                }

                .table-responsive  table th.sorting:hover:after,
                .table-responsive table th.sorting-asc:after, 
                .table-responsive  table th.sorting-desc:after {
                visibility:visible;
                opacity:1;
                }
                .table-responsive    table th.sorting:after, 
                .table-responsive  table th.sorting-asc:after {
                visibility:hidden;
                opacity:0;
                display:inline-block !important;
                }

                .table-responsive .card {
                    min-width: 991px;
                }

                div#content_wrapper {
                overflow-x: auto;
                width: 100%;
                }
                                         
                 
    </style>
    