<template>
<div class="container-fluid">
    <div class="container-wrappero">
        <div class="content-page-header mb-4">
            <div class="breadcrumb-header">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb content_shadow">
                        <li class="breadcrumb-item">
                            <router-link to="/holidays">Holidays</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Add Holidays </li>
                    </ol>
                </nav>
            </div>
        </div>
        <!-- content-page-header end -->
        <div class="content-body  mb-4">
            <div class="add-contact">
                <v-alert dense text type="success" dismissible v-if="fixedSuccess">
                    Successfully added
                </v-alert>
                <v-form class="row g-3 mb-10" @submit.prevent="submitFixedHoliday()" v-model="validFixed" ref="fixedHolidayform">
                    <div class="col-md-12 padding">
                        <label for="Addcontact" class="form-label">Weekly holiday <span class="text-danger">*</span>
                        </label>
                        <div class="d-flex">
                            <v-checkbox v-model="fixedHolidayCheck" @click="fixedHolidayClick" label="If have weekly fixed holiday" outlined dense></v-checkbox>&nbsp;&nbsp;&nbsp;
                            <div class="d-flex" v-if="dayCheck">
                                <v-checkbox v-model="fixedholidays" value="Friday" label="Friday" outlined dense></v-checkbox>
                                <v-checkbox v-model="fixedholidays" value="Saturday" label="Saturday" outlined dense></v-checkbox>
                                <v-checkbox v-model="fixedholidays" value="Sunday" label="Sunday" outlined dense></v-checkbox>
                                <v-checkbox v-model="fixedholidays" value="Monday" label="Monday" outlined dense></v-checkbox>
                                <v-checkbox v-model="fixedholidays" value="Tuesday" label="Tuesday" outlined dense></v-checkbox>
                                <v-checkbox v-model="fixedholidays" value="Wednesday" label="Wednesday" outlined dense></v-checkbox>
                                <v-checkbox v-model="fixedholidays" value="Thursday" label="Thursday" outlined dense></v-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 padding">
                            <v-btn class="mr-4 darken-3" type="button" @click="resetButton()" color="error">
                                Reset
                            </v-btn>
                        </div>
                        <div class="col-md-6 padding">
                            <v-btn color="blue darken-4" class="f-right" type="submit" :loading="loading" dark>
                                Submit
                            </v-btn>
                        </div>

                    </div>
                </v-form>

                <v-form class="row g-3" @submit.prevent="submitOcassionHoliday()" v-model="valid" ref="occassionHolidayform">

                    <div class="row">
                        <v-alert dense text type="success" dismissible v-if="occassionSuccess">
                            Successfully added
                        </v-alert>
                        <div class="col-md-6 padding">
                            <label for="Addcontact" class="form-label">Holidays Date <span class="text-danger">*</span>
                            </label>
                            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="occasionHoliday.date" label="Select Date" :rules="occassionDateRules" prepend-inner-icon="mdi-calendar" prepend-icon="" readonly v-bind="attrs" ref="date" @click:prepend-inner="$refs.date.$el.querySelector('input').click()" v-on="on" solo flat outlined dense></v-text-field>
                                </template>
                                <v-date-picker v-model="occasionHoliday.date" :active-picker.sync="activePicker" min="2022-01-01" max="2028-12-31" @change="save"></v-date-picker>
                            </v-menu>
                        </div>
                        <div class="col-md-6 padding">
                            <label for="Source" class="form-label">Occasion <span class="text-danger">*</span>
                            </label>
                            <v-text-field placeholder="Enter your occasion" v-model="occasionHoliday.occasion" :rules="occassionRules" type="text" outlined dense></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 padding">
                            <label for="Addcontact" class="form-label">Description</label>
                            <v-textarea name="input-7-1" v-model="occasionHoliday.description" placeholder="Enter the occasion description(optional)" outlined dense></v-textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 padding">
                            <v-btn class="mr-4 darken-3" type="button" @click="resetButton1()" color="error">
                                Reset
                            </v-btn>
                        </div>
                        <div class="col-md-6 padding">
                            <v-btn color="blue darken-4" class="f-right" type="submit" :loading1="loading" dark>
                                Submit
                            </v-btn>
                        </div>

                    </div>

                </v-form>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    mapState,
    mapActions
} from 'vuex';
export default {
    name: 'addDesignation',

    data: () => ({
        validFixed: true,
        activePicker: null,
        fixedholidays: [],
        date: null,
        menu: false,
        loading:false,
        valid: false,
        checkbox: false,
        fixedHolidayCheck: false,
        day: {
            fridayCheckbox: false,
            saturdayCheckbox: false,
            sundayCheckbox: false,
            mondayCheckbox: false,
            tuesdayCheckbox: false,
            wednesdayCheckbox: false,
            thursdayCheckbox: false,
        },

        dayCheck: false,

        occasionHoliday: {
            date: '',
            occasion: '',
            description: ''
        },

        loading1: false,

        occassionSuccess: false,
        fixedSuccess: false,

        occassionDateRules: [
            v => !!v || 'Date is required',
        ],
        occassionRules: [
            v => !!v || 'Occasion name is required',
        ],
    }),
    async created() {},
    computed: {

    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },

    },
    methods: {
        ...mapActions('department', ['getDepartments']),
        resetButton() {
            this.dayCheck = !this.dayCheck
            this.$refs.fixedHolidayform.reset();
        },

        resetButton1() {
            this.$refs.occassionHolidayform.reset();
        },

        save(date) {
            this.$refs.menu.save(date)
        },

        fixedHolidayClick() {
            this.dayCheck = !this.dayCheck
        },

        submitFixedHoliday() {
            console.log("holiday array", this.fixedholidays)
            this.loading = true
            if (this.$refs.fixedHolidayform.validate()) {
                let data = {
                    fixedholidays: this.fixedholidays
                }
                this.$store
                    .dispatch("holiday/storeFixedHoliday", data)
                    .then((response) => {
                        this.fixedSuccess = true
                        this.$refs.fixedHolidayform.reset();
                        this.loading = false
                        this.dayCheck = false
                        this.fixedholidays = []
                    })
                    .catch((error) => {
                        this.loading = false
                    });
            } else {
                this.loading = false;
            }
        },

        submitOcassionHoliday() {
            this.loading1 = true
            if (this.$refs.occassionHolidayform.validate()) {
                this.$store
                    .dispatch("holiday/storeOccasionHoliday", this.occasionHoliday)
                    .then((response) => {
                        this.occassionSuccess = true
                        this.$refs.occassionHolidayform.reset();
                        this.loading1 = false
                    })
                    .catch((error) => {
                        this.loading1 = false
                    });
            } else {
                this.loading1 = false;
            }
        }
    }
}
</script>
