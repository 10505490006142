<template>
<div class="container-fluid">
    <div class="container-wrappero">
        <div class="content-page-header mb-4">
            <div class="breadcrumb-header">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb content_shadow">
                        <li class="breadcrumb-item">
                            <router-link to="/useradmin">User Admin</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Add User Admin </li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="content-body  mb-4">
            <div class="add-contact">
                <v-form class="row g-3" @submit.prevent="updateUserAdmin()" v-model="valid" ref="useradminform">
                    <div class="row">
                        <div class="col-md-6 padding">
                            <label class="form-label">Name <span class="text-danger">*</span></label>
                            <v-text-field placeholder="Enter Admin Name" type="text" :rules="nameRules" v-model="useradmin.name" outlined dense></v-text-field>
                        </div>
                        <div class="col-md-6 padding">
                            <label class="form-label">Email <span class="text-danger">*</span></label>
                            <v-text-field placeholder="Enter Email" type="text" :rules="emailRules" v-model="useradmin.email" outlined dense></v-text-field>
                        </div>
                        <div class="col-md-6 padding">
                            <label class="form-label">Password <span class="text-danger">*</span></label>
                            <v-text-field placeholder="Enter Password" :rules="passwordRules" v-model="useradmin.password_view"  :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showpassword ? 'text' : 'password'"
                              dense
                              style="border-radius: 0px"
                              @click:append="showpassword = !showpassword" outlined></v-text-field>
                        </div>
                        <div class="col-md-6 padding">
                            <label for="inputEmail4" class="form-label">Status</label>
                            <v-select
                                :items="status_items"
                                v-model="useradmin.status"
                                :item-text="item=>item.name"
                                :item-value="item=>item.value"
                                outlined
                                dense
                                >
                            </v-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 padding">
                            <v-btn class="mr-4" type="button" @click="resetButton()" color="error">
                                Reset
                            </v-btn>
                        </div>
                        <div class="col-md-6 padding">
                            <v-btn color="blue darken-4" class="f-right" type="submit" :loading="loading" dark>
                                Submit
                            </v-btn>
                        </div>
                    </div>
                </v-form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'EditUserAdmin',

    data: () => ({
        valid: true,
        useradmin: {
            id: '',
            name: '',
            email: '',
            password_view: '',
            status: '',
        },
        loading: false,
        showpassword: false,
        status_items:[
            {id: 0,name: 'Active', value: 1},
            {id: 1,name: 'Inactive', value: 0}
        ],
        nameRules: [
            v => !!v || 'Admin Name is required',
        ],
        emailRules: [
            v => !!v || 'Email is required',
            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
        passwordRules: [
            v => !!v || 'Password is required',
            v => v.length >= 4 || "Min 4 characters",
        ],
        statusRules: [
            v => !!v || 'Status is required',
        ],
    }),
    created() {
        if(this.$route.params.id){
            this.getUserAdmins();
        }
    },
    computed: {
    },
    methods: {
        resetButton() {
            this.$refs.useradminform.reset();
        },
        getUserAdmins()
        {
            this.$store.dispatch("useradmin/UserAdmins",this.$route.params.id).then((res)=>{
                if(res){
                    this.useradmin.id = res.id,
                    this.useradmin.name = res.name,
                    this.useradmin.email = res.email,
                    this.useradmin.password_view = res.password_view
                    this.useradmin.status = res.status
                }
            })
        },

        updateUserAdmin(){
            axios.put("useradmin/"+this.$route.params.id, this.useradmin)
                .then((res) => {
                    this.$router.push('/useradmin')
                })
                .catch((err) => {
                    console.log("Error", err);
                });
        },
    }
}
</script>
