<template>
  <div class="container-fluid">
    <div class="container-wrappero">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/designation">Designation</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Add Designation
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- content-page-header end -->
      <div class="content-body mb-4">
        <div class="add-contact">
          <v-form
            class="row g-3"
            @submit.prevent="submitDesignation()"
            v-model="valid"
            ref="designationform"
          >
            <div class="row">
              <div class="col-md-6 padding">
                <label for="Addcontact" class="form-label"
                  >Department <span class="text-danger">*</span>
                </label>
                <v-select
                  :items="departments"
                  v-model="designation.department_id"
                  :item-text="(item) => item.name"
                  :rules="departmentRules"
                  :item-value="(item) => item.id"
                  label="Select Department"
                  solo
                  flat
                  outlined
                  dense
                ></v-select>
              </div>
              <div class="col-md-6 padding">
                <label for="Source" class="form-label"
                  >Designation name <span class="text-danger">*</span>
                </label>
                <v-text-field
                  placeholder="Enter your Designation"
                  type="text"
                  :rules="designationNameRules"
                  v-model="designation.name"
                  outlined
                  dense
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 padding">
                <label for="Addcontact" class="form-label">Description</label>
                <v-textarea
                  name="input-7-1"
                  placeholder="Enter the designation description(optional)"
                  v-model="designation.description"
                  outlined
                  dense
                ></v-textarea>
              </div>
            </div>
            <div class="submit-section">
              <div class="">
                <v-btn
                  class="mr-4 custom-btn"
                  type="button"
                  @click="resetButton()"
                  color="error"
                >
                  Reset
                </v-btn>
              </div>
              <div class="">
                <v-btn
                  class="custom-btn f-right"
                  color="blue darken-4"
                  type="submit"
                  :loading="loading"
                  dark
                >
                  Submit
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "addDesignation",

  data: () => ({
    valid: true,
    designation: {
      department_id: "",
      name: "",
      description: "",
    },
    loading: false,
    departmentRules: [(v) => !!v || "Department is required"],
    designationNameRules: [(v) => !!v || "Designation name is required"],
  }),
  async created() {
    this.getDepartments();
  },
  computed: {
    ...mapState("department", ["departments"]),
  },
  methods: {
    ...mapActions("department", ["getDepartments"]),
    resetButton() {
      this.$refs.designationform.reset();
    },
    submitDesignation() {
      this.loading = true;
      if (this.$refs.designationform.validate()) {
        this.$store
          .dispatch("designation/storeDesignation", this.designation)
          .then((response) => {
            this.$router.push("/designation");
            this.$refs.designationform.reset();
          })
          .catch((error) => {
            // console.log(error.response.data.errors);
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
<style>
    .submit-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>