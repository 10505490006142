<template>
  <main>
      <!-- <Navbar /> -->
      <!-- <CoreView /> -->
      <!-- <Footer /> -->

  </main>
</template>

<script>
export default {
    name: "App",
    components: {
        // Navbar: () => import('@/components/core/navbar.vue'),
        Footer: () => import('@/components/core/footer.vue'),
        CoreView: () => import('@/components/core/coreView.vue')
    },
    mounted(){
      this.checkUserLogin()
    },
    methods: {
      checkUserLogin() {
      // send request to check if the user is logged otherwise redirect him to login screen
      if (
        localStorage.getItem("auth_token") !== null &&
        localStorage.getItem("auth_token") !== undefined
      ) {
        axios
          .get("/api/check-login", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          })
          .then((response) => {
            if (response.data.success != 1) {
              localStorage.removeItem("auth_token");
              localStorage.removeItem("is_authenticated");
              localStorage.removeItem("user_data");
              this.$router.push("/admin");
            }
          })
          .catch((err) => {
            if (err) {
              localStorage.removeItem("auth_token");
              localStorage.removeItem("is_authenticated");
              localStorage.removeItem("user_data");
              this.$router.push("/admin");
            }
          });
      }
    }, 
    }
}
</script>

<style>
.material-table td, .material-table th {
    border: 1px solid #e3ebf3;
}

.material-table tbody tr:nth-of-type(odd){background-color: #f5f5f5;}
.material-table tbody tr td{
    font-size: 13px;
    color: rgb(0 0 0);
    border-bottom: 1px solid #e3ebf3 !important;
    
}
.material-table thead tr th {
  color: rgb(0 0 0);
    font-size: 13px;
    height: 45px !important; 
}
.swal2-title{
  font-size:25px!important;
}

.custom-group{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.custom-group .form-label {
    margin-bottom: 0.5rem;
    width: 140px;
    text-align: end;
}

/* .material-table tbody tr:hover:nth-of-type(even) {
  background-color: #f5f5f5;
} */


</style>