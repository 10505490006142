export default {
    namespaced: true,
  
    state: () => ({
      employees: [],
      employeeforsalaries: [],
      employeeId: {}
    }),
  
    getters: {
      employees(state){
        return state.employees
      },
      employeeforsalaries(state){
        return state.employeeforsalaries
      },
      employeeId(state){
        return state.employeeId
      },
    },
  
    mutations: {
      populateEmployee(state, newEmployee){
        state.employees = newEmployee;
      },
      populateEmployeeforSalaries(state, newEmployeeforSalary){
        state.employeeforsalaries = newEmployeeforSalary;
      },
      populateEmployeeId(state, newEmployee){
        state.employeeId = newEmployee;
      },

      saveEmployee(state,newEmployee)
      {
        state.employees.push(newEmployee);
      },
      UpdateEmployee(state,newEmployee)
      {
       
      }
  
    },
  
    actions: {
      async getEmployees({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("employee")
          .then(res => {
            console.log("Employee Data::: ",res.data.data)
            resolve(res);
            commit("populateEmployee",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      getEmployeeforSalaries({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("employeeforsalary")
          .then(res => {
            resolve(res);
            console.log(res);
            commit("populateEmployeeforSalaries", res.data.data);
          })
          .catch(error => {
            reject(error)
          })
        });
      },
      async getEmployeeId({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("last_employee_id")
          .then(res => {
            console.log("Employee Data::: ",res.data.data)
            resolve(res);
            commit("populateEmployeeId",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async AddEmployee({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.post("employee",apiArg)
          .then(res => {
            console.log("Employee Data::: ",res.data.data)
            resolve(res);
            commit("saveEmployee",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async UpdateEmployee({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.put("employee/"+apiArg.id,apiArg)
          .then(res => {
            console.log("Employee Data::: ",res.data.data)
            resolve(res);
            commit("updateEmployee",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async Employeedetails(ctx,apiArg){
        let isSuccess = false
        await axios.get("employee/"+apiArg+"/edit").then(response => {
          isSuccess = response.data.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
        },

      
    }
  };