<template>
<div class="container-fluid">
    <div class="container-wrapper">
        <div class="row">
            <div class="float-right text-right">
                <button @click="AddDepartment()" class="btn btn-sm custom-btn mb-3"><span><i class="fa fa-plus"></i></span> Add Department</button>
            </div>
        </div>
        <div class="table-responsive">
            <datatable class="mt-2" title="Departments" :columns="tableColumns1" :rows="finalDepartments" :exactSearch='true' :exportable="true" :printable="false">
                <th slot="thead-tr">
                    Actions
                </th>
                <template slot="tbody-tr" scope="props">
                    <td>
                        <button class="data-btn mr-2" @click="e => editItem(props.row, e)">
                            <i class="fa fa-edit"></i>
                        </button>
                        <button class="data-btn" @click="e => deleteItem(props.row, e)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </template>
            </datatable>
        </div>
    </div>

</div>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import DataTable from "vue-materialize-datatable";
export default {
    name: 'Dashboard',

    data: () => ({
        tableColumns1: [{
                label: "Sl",
                field: "sno",
                numeric: false,
                html: false
            },
            {
                label: "Name",
                field: "name",
                numeric: false,
                html: false
            },
            {
                label: "Description",
                field: "description",
                numeric: false,
                html: false
            },
            {
                label: "Status",
                field: "status",
                numeric: false,
                html: '<span>',
            },
        ],
        // tableRows2: [
        // 	{
        // 		charName: "Abu",
        // 		firstAppearance: "Alladin (1992)",
        // 		createdBy: "Joe Grant",
        // 		voicedBy: "Frank Welker"
        // 	},
        // 	{
        // 		charName: "Magic Carpet",
        // 		firstAppearance: "Alladin (1992)",
        // 		createdBy: "Randy Cartwright",
        // 		voicedBy: "N/A"
        // 	},
        // 	{
        // 		charName: "The Sultan",
        // 		firstAppearance: "Alladin (1992)",
        // 		createdBy: "Navid Negahban",
        // 		voicedBy: "Douglas Seale"
        // 	}
        // ],

    }),
    components: {
        "datatable": DataTable
    },

    async created() {
        this.getDepartments();
    },
    computed: {
        ...mapState('department', ['departments']),
        finalDepartments() {
            return this.departments.length > 0 ? this.departments.map((department, sl) => ({
                ...department,
                sno: sl + 1,
                description: (department.description && department.description.length > 50 ? department.description.substring(0, 50) + ".." : department.description),
                status: department.status == 1 ? '<span class="">Active </span>' : '<span class=""badge badge-success"">De-active </span>',
            })) : [];
        }
    },

    methods: {
        ...mapActions('department', ['getDepartments']),
        AddDepartment() {
            this.$router.push('/add_department').catch(() => {});
        },
        editItem(props, event) {
            console.log('props data peyechi', props, event);
            this.$router.push({
                name: 'EditDepartment',
                params: {
                    id: props.id
                }
            }).catch(() => {});
        },
        deleteItem(props, e) {
            this.$swal({
                title: "Delete Department",
                text: "Are you sure you want to delete this department?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Delete!"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed

                    axios
                        .delete("department/" +
                            props.id
                        )
                        .then((res) => {
                            // this.feedlist = res.data;
                            this.$swal('Successfully deleted');
                            this.getDepartments();
                        })
                        .catch((err) => {
                            console.log("Error", err);
                        });
                }
            });
        }
    },
};
</script>

<style>
/* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
.data-btn {
    background-color: #607d8b !important;
    color: #fff;
    padding: 5px 10px 5px 8px !important;
}

.custom-btn {
    background-color: #607d8b !important;
}
</style>
