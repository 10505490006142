<template>
<div class="container-fluid">
    <div class="container-wrapper">
        <div class="row">
            <div class="float-right text-right">
                <button @click="downloadFile()" class="btn btn-sm custom-btn mb-3 mr-2"><span><i class="fa fa-print"></i></span> Export</button>
                <button @click="AddEmployee()" class="btn btn-sm custom-btn mb-3"><span><i class="fa fa-plus"></i></span> Add Employee</button>
                <button @click="printDiv('print')" class="btn btn-sm custom-btn mb-3 ml-1"><span><i class="fa fa-print"></i></span> Print</button>
            </div>
        </div>
        <div class="table-responsive">
            <datatable class="mt-2" title="Employees" :columns="tableColumns1" :rows="finalEmployees" :exportable="false" :printable="false" id="print">
                <th slot="thead-tr">
                    Actions
                </th>
                <template slot="tbody-tr" scope="props">
                    <td>
                        <button class="data-btn mr-2" @click="e => editItem(props.row, e)">
                            <i class="fa fa-edit"></i>
                        </button>
                        <button class="data-btn" @click="e => deleteItem(props.row, e)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </template>
            </datatable>
        </div>
    </div>
</div>
</template>

<script>
import exportFromJSON from "export-from-json";
import {
    mapActions,
    mapState
} from "vuex";
import DataTable from "vue-materialize-datatable";
export default {
    name: 'Dashboard',
    data: () => ({
        tableColumns1: [{
                label: "Sl",
                field: "sno",
                numeric: false,
                html: false
            },
            {
                label: "Employee Id",
                field: "employee_id",
                numeric: false,
                html: false,
            },
            {
                label: "Employee Image",
                field: "image",
                numeric: false,
                html: '<img src="image">',
            },
            {
                label: "Employee Name",
                field: "name",
                numeric: false,
                html: false
            },
            {
                label: "Phone",
                field: "phone",
                numeric: false,
                html: false
            },
            {
                label: "Department/Designation",
                field: "dep_design",
                numeric: false,
                html: '<br />'
            },
            {
                label: "Status",
                field: "status",
                numeric: false,
                html: '<span>',
            },
        ],

        customButton: [{
            hide: false,
            icon: 'print'
        }],

    }),
    components: {
        "datatable": DataTable
    },

    async created() {
        this.getEmployees();
    },
    computed: {
        ...mapState('employee', ['employees']),
        finalEmployees() {
            return this.employees.length > 0 ? this.employees.map((employee, sl) => ({
                ...employee,
                sno: sl + 1,
                image: '<img class="img-custom" src="' + (employee.image ? employee.image : require('@/assets/backend/img/user-image.png')) + '" >',
                dep_design: 'Department: ' + (employee.department ? employee.department.name : '') + '<br />' + 'Designation: ' + (employee.designation ? employee.designation.name : ''),
                status: employee.status == 1 ? '<span class="">Active </span>' : '<span class=""badge badge-success"">De-active </span>',
            })) : [];
        }
    },

    methods: {
        ...mapActions('employee', ['getEmployees']),
        ...mapActions('employee', ['getEmployeeId']),

        AddEmployee() {
            this.$store.dispatch("employee/getEmployeeId", this.employee).then((response) => {
                this.$router.push('/add_employee').catch(() => {})
            }).catch(() => {});

        },
        editItem(props, event) {
            console.log('props data peyechi', props, event);
            this.$router.push({
                name: 'EditEmployee',
                params: {
                    id: props.id
                }
            }).catch(() => {});
        },
        downloadFile() {
            const data = this.employees.map((employee, sl) => {
                return {
                    id: employee.id,
                    employee_name: employee.name,
                    email: employee.email,
                    phone: employee.phone,
                    designation: employee.designation ? employee.designation.name : '',
                    department: employee.department ? employee.department.name : '',
                    joining_date: employee.joining_date,
                    basic_salary: employee.regular_salary,
                    house_rent: employee.house_rent,
                    medical_allowance: employee.medical_allowance,
                    incentive: employee.incentive,
                    provident_fund: employee.provident_fund,
                }
            });
            const fileName = "Employee List";
            const exportType = exportFromJSON.types.csv;

            if (data) exportFromJSON({
                data,
                fileName,
                exportType
            });
        },
        deleteItem(props, e) {
            this.$swal({
                title: "Delete Employee",
                text: "Are you sure you want to delete this Employee?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Delete!"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed

                    axios
                        .delete("employee/" +
                            props.id
                        )
                        .then((res) => {
                            // this.feedlist = res.data;
                            this.$swal('Successfully deleted');
                            this.getEmployees();
                        })
                        .catch((err) => {
                            console.log("Error", err);
                        });
                }
            });
        },
        printDiv(divID) {
                var divContents = document.getElementById("print").innerHTML;
                var a = window.open('', '', '');
                a.document.write('<html><head><style type="text/css">');
                // a.document.write('<link rel="stylesheet" href="/assets/backend/css/bootstrap.min.css" type="text/css" />');
                a.document.write('body,td,th{font-family:Arial, Helvetica, sans-serif;font-size:13px;color:#000000}');
                a.document.write('.material-table td,.material-table th {border: 1px solid #e3ebf3; }');
                a.document.write('thead tr th {color: rgb(0 0 0);    font-size: 13px;    height: 45px !important;}');
                a.document.write('table{border-collapse:collapse; margin:0 auto;}');
                a.document.write('table th:last-child, table td:last-child{display:none !important}');
                a.document.write('.table-header{display:none !important}');
                a.document.write('.table-footer{display:none !important}');
                a.document.write('#search-input-container{display:none !important}');
                a.document.write('tbody, td, tfoot, th, thead, tr {border: 1px solid #e3ebf3 !important; text-align: center;}');
                a.document.write('thead th{font-size:13px!important;}');
                a.document.write('table td, table tr td {    padding: 3px 2px !important;}');
                a.document.write('table td, img {    height: 70px !important; width :70px !important}');
                a.document.write('.bottom-line{position:fixed; transform: translateX(-50%); left:50%;bottom:0}');
                a.document.write('h3,h2,h1,p{text-align:center;}');
                a.document.write('</style></head><body> <h1>Jamalpur Central Hospital Ltd</h1><h2>Amlapara, Jamalpur</h2><h3>Employee List </h3>');
                a.document.write(divContents);
                a.document.write('<p class="bottom-line"> This report is system generated, no signiture needed </p>');
                a.document.write('</body></html>');
                a.document.close(); 
                a.focus();
                a.print();
                // document.body.innerHTML = oldPage;
            }
        // 	printerFunc(e) {
        // 		e.preventDefault();
        //     //row contains the clicked object from `rows`
        //         console.log('okey')
        //   }
    },
};
</script>

<style>
/* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
.data-btn {
    background-color: #607d8b !important;
    color: #fff;
    padding: 5px 10px 5px 8px !important;
}

.custom-btn {
    background-color: #607d8b !important;
}

.img-custom {
    width: 100px;
    height: 100px;
    padding: 5px 0;
}
</style>
