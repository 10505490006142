<template>
    <div class="container-fluid">
        <div class="container-wrapper">
            <div class="row">
                <div class="float-right text-right">
                    <router-link to="/add-salary-setting">
                        <button class="btn btn-sm custom-btn mb-3"><span><i class="fa fa-plus"></i></span> Add Salary Setting</button>
                    </router-link>
                  
                </div>
            </div>
            <datatable class="mt-2"
                title="Salary Setting"
                :columns="tableColumns1"
                :rows="finalSalaySetting"
                :exactSearch='true' :exportable="true" :printable="false"
                
                >
            
        <th slot="thead-tr">
            Actions
        </th>
        <template slot="tbody-tr" scope="props">
            <td>
                <button class="data-btn mr-2"
                @click="e => editItem(props.row, e)">
                <i class="fa fa-edit"></i>
            </button>
            <button class="data-btn"
                @click="e => deleteItem(props.row, e)">
                <i class="fa fa-trash"></i>
            </button>
            </td>
        </template>
            </datatable>
        </div>
    
    </div>
    </template>
    
    <script>
    import {mapActions,mapState} from "vuex";
    import DataTable from "vue-materialize-datatable";
    export default {
        name: 'Dashboard',
    
        data: () => ({
            tableColumns1: [
                {
                    label: "Sl",
                    field: "sno",
                    numeric: false,
                    html: false
                },
                
                {
                    label: "Type",
                    field: "type",
                    numeric: false,
                    html: false,
                },
                {
                    label: "With and Without Basic",
                    field: "withandwithoutbasic",
                    numeric: false,
                    html: false
                },
                {
                    label: "Medical Allowance",
                    field: "medical_allowance",
                    numeric: false,
                    html: false
                },
                {
                    label: "Provident Found",
                    field: "provident_found",
                    numeric: false,
                    html: false
                },
                {
                    label: "House Rent",
                    field: "house_rent",
                    numeric: false,
                    html: false
                },
                {
                    label: "Incentive",
                    field: "incentive",
                    numeric: false,
                    html: false
                }
            ],
    
        }),
        components: {
            "datatable": DataTable
        },
    
        async created() {
           this.getSalarySetting();
        },
        computed: {
            ...mapState('salary_setting',['settings']),
            finalSalaySetting()
            {
                return this.settings.length > 0 ? this.settings.map((setting,sl)=>({...setting,sno: sl + 1, 
                    type: setting.type == 1 ? 'Amount' : 'Percentage', 
                    withandwithoutbasic: setting.with_without_basic == 1 ? 'With basic salary' : 'without basic salary',
                    medical_allowance: setting.type == 1 ? setting.medical_allowance + '' :  setting.medical_allowance + '%',
                    provident_found: setting.type == 1 ? setting.provident_found + '' :  setting.provident_found + '%',
                    house_rent: setting.type == 1 ? setting.house_rent + '' :  setting.house_rent + '%',
                    incentive: setting.type == 1 ? setting.incentive + '' :  setting.incentive + '%',
                })) : [];
            }
        },
    
        methods: { 
            ...mapActions('salary_setting',['getSalarySetting']),
            editItem(props,event)
            {
                console.log('props data peyechi', props, event);
                this.$router.push({name:'EditSetting', params: { id: props.id}}).catch(()=>{});
            },
            deleteItem(props, e) {
                this.$swal({
                    title: "Delete Employee",
                    text: "Are you sure you want to delete this Employee?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Delete!"
                }).then((result) => { // <--
                    if (result.value) { // <-- if confirmed
                   
                    axios
                        .delete("salary_setting/" +
                            props.id
                        )
                        .then((res) => {
                            // this.feedlist = res.data;
                            this.$swal('Successfully deleted');
                            this.getSalarySetting();
                        })
                        .catch((err) => {
                            console.log("Error", err);
                        });
                    }
                });
            }
        },
    };
    </script>
    
    <style>
       /* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
       .data-btn{
            background-color: #607d8b!important;
            color: #fff;
            padding: 5px 10px 5px 8px!important;
       }
       .custom-btn{
        background-color: #607d8b!important;
       }
       .img-custom{
        width:100px;
        height:100px;
        padding:5px 0;
       }
       
    </style>
    