export default {
    namespaced: true,
  
    state: () => ({
      miscellaneouses: []
    }),
  
    getters: {
      miscellaneouses(state){
        return state.miscellaneouses
      }
    },
  
    mutations: {
      populateMiscellaneous(state, newMiscellaneous){
        state.miscellaneouses = newMiscellaneous;
      },

      saveMiscellaneous(state,newMiscellaneous)
      {
        state.miscellaneouses.push(newMiscellaneous);
      },
      updateMiscellaneous(state,newMiscellaneous)
      {
       
      }
    },
  
    actions: {
      async getMiscellaneouses({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("miscellaneous")
          .then(res => {
            console.log("Miscellaneous Data::: ",res.data.data)
            resolve(res);
            commit("populateMiscellaneous",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async AddMiscellaneous({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.post("miscellaneous",apiArg)
          .then(res => {
            console.log("Miscellaneous Data::: ",res.data.data)
            resolve(res);
            commit("saveMiscellaneous",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async UpdateMiscellaneous({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.put("miscellaneous/"+apiArg.id,apiArg)
          .then(res => {
            console.log("Miscellaneous Data::: ",res.data.data)
            resolve(res);
            commit("updateMiscellaneous",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async Miscellaneousdetails(ctx,apiArg){
        let isSuccess = false
        await axios.get("miscellaneous/"+apiArg+"/edit").then(response => {
          isSuccess = response.data.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
        },
    }
  };