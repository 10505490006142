<template>
  <div class="container-fluid">
    <div class="container-wrappero">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/provident_fund">Provident Fund</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Add Provident Fund
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- content-page-header end -->
      <div class="content-body mb-4">
        <div class="add-contact">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Provident fund details
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <v-form
                    @submit="saveProvidentFund"
                    v-model="valid"
                    ref="providentform"
                    lazy-validation
                  >
                    <div class="row g-3">
                      <div class="col-md-6">
                        <label for="inputEmail4" class="form-label"
                          >Employee <span class="text-danger">*</span></label
                        >
                        <v-autocomplete
                          placeholder="Select Employee"
                          type="text"
                          v-model="provident.employee_id"
                          :rules="employeeRules"
                          @change="setEmployee"
                          :items="employees"
                          :item-text="(item) => item.name"
                          :item-value="(item) => item.id"
                          outlined
                          dense
                        ></v-autocomplete>
                      </div>

                      <div class="col-md-3">
                        <label for="provident fund" class="form-label">Previous Provident Fund <span class="text-danger">*</span></label>
                        <v-text-field
                          placeholder="Enter Previous Provident fund"
                          label=""
                          type="number"
                          v-model="provident.previous_provident_fund"
                          :rules="requiredRules"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-3">
                        <label for="provident fund" class="form-label">Previous Month <span class="text-danger">*</span></label>
                        <v-text-field
                          placeholder="Enter Previous Month(ex:12)"
                          label=""
                          type="number"
                          v-model="provident.previous_month"
                          :rules="requiredRules"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                     
                      <div class="col-md-3" v-if="(employeeSelect != null)">
                        <label for="provident fund" class="form-label">{{employeeSelect.name}} Basic Salary <span class="text-danger">*</span></label>
                        <v-text-field
                          placeholder="Enter Previous Month(ex:12)"
                          label=""
                          type="number"
                          v-model="provident.salary"
                          :rules="requiredRules"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-3" v-if="(employeeSelect != null)">
                        <label for="provident fund" class="form-label">Provident Fund <span class="text-danger">*</span></label>
                        <v-text-field
                          placeholder="Enter Previous Month(ex:12)"
                          label=""
                          type="number"
                          v-model="provident.provident_fund"
                          :rules="requiredRules"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail4" class="form-label"
                          >Status <span class="text-danger">*</span></label
                        >
                        <v-select
                          :items="status_items"
                          v-model="provident.status"
                          :item-text="(item) => item.name"
                          :item-value="(item) => item.value"
                          :rules="requiredRules"
                          outlined
                          dense
                        ></v-select>
                      </div>
                    </div>
                    <div class="submit-section">
                      <div class="">
                        <v-btn
                          class="mr-4 custom-btn"
                          type="button"
                          @click="resetButton()"
                          color="error"
                        >
                          Reset
                        </v-btn>
                      </div>
                      <div class="">
                        <v-btn
                          class="custom-btn f-right"
                          color="blue darken-4"
                          type="submit"
                          :loading="loading"
                          dark
                        >
                          Submit
                        </v-btn>
                      </div>
                    </div>
                  </v-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { de } from "vue-materialize-datatable/src/locales";
import { mapState, mapActions } from "vuex";
export default {
  name: "AddRoaster",

  data: () => ({
    provident: {
      id: "",
      employee_id: "",
      previous_provident_fund: "",
      previous_month: "",
      provident_fund: "",
      salary: "",
      status: "",
    },
    employeeSelect: null,
    loading: false,
    valid: true,
    error: "",
    status_items: [
      { id: 0, name: "Active", value: 1 },
      { id: 1, name: "De-active", value: 0 },
    ],
    requiredRules: [
      (v) => !!v || "Field is required",
    ],
    employeeRules: [(v) => !!v || "Select employee"],
  }),
  async created() {
    this.getEmployees();
    if (this.$route.params.id) {
      this.getFundDetails();
    }
  },
  computed: {
    ...mapState("employee", ["employees"]),
  },
  methods: {
    ...mapActions("employee", ["getEmployees"]),
    saveProvidentFund(event) {
      event.preventDefault();
      this.loading = true;
      let isValidate = true;
      this.error = "";
      this.$refs.providentform.validate();
      this.$refs.providentform.inputs.forEach((input) => {
        if (input.hasError) {
          isValidate = false;
        }
      });
      if (!isValidate) {
        this.loading = false
        return;
      }
      if (this.provident.id == "") {
       
        this.$store
          .dispatch("provident/AddProvidentFund", this.provident)
          .then((response) => {
            this.$router.push("/provident_fund").catch(() => {});
          })
          .catch(() => {});
      } else {
        this.$store
          .dispatch("provident/UpdateProvidentFund", this.provident)
          .then((response) => {
            this.$router.push("/provident_fund").catch(() => {});
          })
          .catch(() => {});
      }
    },
    setEmployee(){
      let employee = this.employees.find(
          (employee) => employee.id == this.provident.employee_id
        );
        this.employeeSelect = employee
        this.provident.salary = employee.regular_salary
        this.provident.provident_fund = employee.provident_found
    },
    // getDateRange()
    // {
    //   if(this.roaster.from_date && this.roaster.to_date){
    //     let data = {
    //       from_date : this.roaster.from_date,
    //       to_date : this.roaster.to_date,
    //       employee_id: this.roaster.emp_id
    //     }
    //     this.$store
    //     .dispatch("roaster/getDateRange", data)
    //     .then((res) => {
    //       console.log("response data peyechi",res);
    //       let current_range = res;
    //       this.roaster.dates = [];
    //       current_range.forEach((range)=>{
    //         let last_id = this.roaster.dates.length ? this.roaster.dates[this.roaster.dates.length - 1].id : 0;
    //         this.roaster.dates.push({
    //             id:last_id + 1,
    //             date: range,
    //             shift: '',
    //             roaster_id: '',
    //             start_time:'',
    //             end_time: '',
    //         })
            
    //       })
    //       console.log("roaster date range peyechi", this.roaster.dates)
    //     })
    //   }
    // },
    getFundDetails() {
      this.$store
        .dispatch("provident/ProvidentFunddetails", this.$route.params.id)
        .then((res) => {
          if (res) {
            this.employeeSelect = this.employees.find((employee) => employee.id == res.employee_id);
              (this.provident.id = res.id),
              (this.provident.employee_id = res.employee_id),
              (this.provident.previous_provident_fund = res.previous_provident_fund),
              (this.provident.provident_fund = res.provident_fund),
              (this.provident.salary =  this.employeeSelect.regular_salary),
              (this.provident.previous_month = res.previous_month),
              (this.provident.status = res.status);
          }
        });
    },
    resetButton() {
      this.$refs.providentform.reset();
    },
  },
};
</script>
<style>
    .submit-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>